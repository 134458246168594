import { HashRouter ,Route } from 'react-router-dom'
import Login from './pages/login'
import Home from './pages/home'
import Head from './common/head'
import About from './pages/about'
import Pay from './pages/pay'
import Reseale from './pages/reseale'
import Deposit from './pages/pay/components/deposit'
import Log from './pages/pay/components/log'
import Member from './pages/pay/components/member'
import Withdraw from './pages/pay/components/withdraw'
import Opern from './pages/reseale/components/opern'
import Micro from './pages/reseale/components/micro'
import My from './pages/reseale/components/my'
import MyOpern from './pages/reseale/components/my_opern'
import MyMicro from './pages/reseale/components/my_micro'
import OpernDetail from './pages/reseale/components/opern_detail'
import MicroDetail from './pages/reseale/components/micro_detail'
import Personal  from './pages/personal'
import Statistics from './pages/personal/components/statistics'
function App() {
  return (
    <HashRouter>
      <div className="App">
            <Head></Head>
            <Route path="/" exact component={Home}></Route>
            <Route path="/login" exact component={Login}></Route>
            <Route path="/about" exact component={About}></Route>
            <Route path="/pay" render={ () => 
                <Pay>
                    <Route path="/pay" exact component={Deposit}></Route>
                    <Route path="/pay/log"  component={Log}></Route>  
                    <Route path="/pay/member"  component={Member}></Route> 
                    <Route path="/pay/withdraw"  component={Withdraw}></Route>   
                </Pay>
                }>
            </Route>
            <Route path="/reseale"   render={ () => 
               <Reseale>
                    <Route path="/reseale" exact component={Opern}></Route>
                    <Route path="/reseale/micro"  component={Micro}></Route>
                    <Route path="/reseale/me"  render= { () => 
                        <My>
                            <Route path="/reseale/me"  exact component={MyOpern}></Route>
                            <Route path="/reseale/me/micro"  component={MyMicro}></Route>
                            <Route path="/reseale/me/opern_detail/:id"  component={OpernDetail}></Route>
                            <Route path="/reseale/me/micro_detail/:id"  component={MicroDetail}></Route>
                        </My>
                    }></Route>    
               </Reseale> 
            }>
            </Route>
            <Route path="/personal"  render={ () => 
                <Personal>
                     <Route path="/personal" exact component={Statistics}></Route>
                </Personal>
            }>

            </Route>
      </div>
    </HashRouter>
  );
}

export default App;
