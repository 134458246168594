import request from '../utils/request'
 
 
 /**
 * 获取我的详情
 * @returns  
 */
  export function fetchUserInfo () {
    return request({
        url: '/api/appUser/details'
    })
  }

  /**
   * 获取认证详情
   */
  export function fetchUserAuth (params) {
    return request({
      url: '/api/appUser/queryById',
      params
    })
  }
  

