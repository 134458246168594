import request from '../utils/request'

/**
 * 手机验证码
 */

export function handleSendMessage (data) {
    return request({
        url: '/api/sms',
        method: 'post',
        data,
        json: true
    })
} 

/**
 * 提现
 */
export function handleWithDrawMoney (data) {
    return request({
        url: '/api/appAdvance/add',
        method: 'post',
        data,
        json: true
    })
}


  /**
   * 获取统计数
  */
   export function fetchCount () {
    return request({
      url: '/api/appUser/count'
    })
  }


  /**
   * 获取收益列表
   */
  export function fetchProfit (params) {
    return request({
        url: '/api/appUser/countProfitList',
        params
    })
  } 

/**
* 成交量列表查询
*/
  export function fetchOrder (params) {
    return request({
        url: '/api/appUser/countOrderList',
        params
    }) 
  }