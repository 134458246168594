
import "../../../static/css/personal_statistics.sass"
import curriculumIcon from '../../../static/images/curriculum.png'
import personalIcon from '../../../static/images/personal.png'
import musicIcon from '../../../static/images/music@blue.png'
import EchartsLine from '../../../common/line'
import EchartsBar from "../../../common/bar"
import Cash from "../../../common/cash"
import { connect } from 'react-redux'
import { createActions } from "../store"
import { withRouter } from 'react-router-dom'
import React,{ useEffect } from 'react'

const Statistics = (props) => {
    const { isWithDraw,countList } = props
    useEffect(() => {
        // other code
        props.fetchCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])
    return (
        <div className="statistics-box">
            
            {
               isWithDraw
                 ? <Cash isShow={isWithDraw}  handleIsShow={() => props.changeIsShow(false)}></Cash>
                 : null
            }
          
            <div className="statistics-num">
                <div className="statistics-num-item">
                    <div>
                        <img src={personalIcon} alt="icon"   />
                    </div>
                    <div className="statistics-num-info">
                        <div className="statistics-num-title">粉丝数量</div>
                        <div className="statistics-num-unit">单位：人</div>
                        <div className="statistics-num-n">{countList.get('fansCount')}</div>
                    </div>
                </div>
                <div className="statistics-num-item">
                    <div>
                        <img src={musicIcon} alt="icon" />
                    </div>
                    <div className="statistics-num-info">
                        <div className="statistics-num-title">已发布曲谱数量</div>
                        <div className="statistics-num-unit">单位：份</div>
                        <div className="statistics-num-n">{countList.get('musicScoreCount')}</div>
                    </div>
                </div>
                <div className="statistics-num-item">
                    <div>
                        <img src={curriculumIcon} alt="icon"  />
                    </div>
                    <div className="statistics-num-info">
                        <div className="statistics-num-title">已发布微课数量</div>
                        <div className="statistics-num-unit">单位：节</div>
                        <div className="statistics-num-n">{countList.get('courseCount')}</div>
                    </div>
                </div>
            </div>

            <div className="profit-box">
                <div className="profit-left">
                    <div className="profit-left-t">
                        <div className="profit-item">
                            <div className="profit-item-k">曲谱总收益:</div>
                            <div className="profit-item-v">{countList.get('musicScoreTotalProfit')}</div>
                        </div>
                        <div className="profit-item">
                            <div className="profit-item-k">曲谱今日收益:</div>
                            <div className="profit-item-v">{countList.get('musicScoreTodayProfit')}</div>
                        </div>
                    </div>
                    <div className="profit-left-b">
                        <div className="profit-item">
                            <div className="profit-item-k">微课总收益:</div>
                            <div className="profit-item-v">{countList.get('courseTotalProfit')}</div>
                        </div>
                        <div className="profit-item">
                            <div className="profit-item-k">微课今日收益:</div>
                            <div className="profit-item-v">{countList.get('courseTodayProfit')}</div>
                        </div>
                    </div>
                </div>
                <div className="profit-right">
                    <div className="profit-right-btn" onClick={() => props.changeIsShow(true)}>提现</div>
                    <div className="profit-right-desc">计数单位：博拉币</div>
                </div>
            </div>

            <EchartsLine></EchartsLine>

            <div className="profit-box">
                <div className="profit-left">
                    <div className="profit-left-t">
                        <div className="profit-item">
                            <div className="profit-item-k">曲谱成交总数(本):</div>
                            <div className="profit-item-v">{countList.get('musicScoreOrderNum')}</div>
                        </div>
                        <div className="profit-item">
                            <div className="profit-item-k">曲谱今日成交数量(本):</div>
                            <div className="profit-item-v">{countList.get('musicScoreTodayOrderNum')}</div>
                        </div>
                    </div>
                    <div className="profit-left-b">
                        <div className="profit-item">
                            <div className="profit-item-k">微课成交总数(节):</div>
                            <div className="profit-item-v">{countList.get('courseOrderNum')}</div>
                        </div>
                        <div className="profit-item">
                            <div className="profit-item-k">微课今日成交数量(节):</div>
                            <div className="profit-item-v">{countList.get('courseTodayOrderNum')}</div>
                        </div>
                    </div>
                </div>
            </div>

            <EchartsBar></EchartsBar>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        isWithDraw: state.getIn(['personal','isWithDraw']),
        countList: state.getIn(['personal','countList'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        changeIsShow (val) {
            dispatch(createActions.changeIsShow(val))
        },
        fetchCount () {
            dispatch(createActions.fetchCount())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Statistics))