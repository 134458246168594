import "../static/css/cash.sass"
import rmIcon from '../static/images/close.png'
import { CSSTransition } from 'react-transition-group'
import '../static/css/animation.css'
import { connect } from 'react-redux'
import { createActions } from "../pages/personal/store"
import { withRouter } from 'react-router-dom'
import { message } from 'antd'
import React,{ PureComponent } from 'react'

let timer = null

class PopupChoose extends PureComponent{
    constructor () {
        super()
        this.state = {
            isFetch: false,
            num: 60
        }
        this.handleSendMessage = this.handleSendMessage.bind(this)
        this.handleWithDraw = this.handleWithDraw.bind(this)
    }
    componentWillUnmount() {
        clearInterval(timer)
    }
    handleSendMessage (phone,mode) {
        if (!/^1[3|4|5|7|8][0-9]{9}$/.test(phone)) {
           return  message.warning('请输入正确的手机号')
        }
        this.handleCountDown()
        this.props.fetchSmsMessage(phone,mode)
    }
    componentDidMount () {
        this.props.changeInputPhone({target: { value : this.props.user.get('phone')}})
    }
    handleCountDown () {
        clearInterval(timer)
        this.setState({
            isFetch: true
        })
        timer = setInterval(() => {
            this.setState({
                num: this.state.num - 1
            }, () => {
                console.log(this.state.num)
                 if (this.state.num === -1 ) {
                     clearInterval(timer)
                     this.setState({
                         isFetch: false,
                         num: 60
                     })
                 }
            })
           
        },1000)
    }

    handleWithDraw () {
        const { bola, name, phone , code, bankName , bankAccount, handleWithDrawMoney } = this.props
        if (!bola || !name || !phone || !code || !bankName || !bankAccount) {
           return  message.warning('请完善提现信息')
        }
        handleWithDrawMoney({bola, name, phone, code, bankName, bankAccount})
    }
    render () {
        const { bola, name, phone ,code, bankName ,bankAccount } = this.props
        return (
            
           
                
               
                    <div className="popup-box popup-box-l">
                        <div className="popup-cover"></div>
                        <div className="popup-choose">
                            <div className="popup-choose-title">
                                <div className="popup-choose-title-text">提现</div>
                                <img className="del-icon" src={rmIcon}  alt="remove icon" onClick={this.props.handleIsShow}/>
                            </div>
                            <div className="popup-choose-inpbox">
                                <div className="popup-choose-title">提现金额</div>
                                <input 
                                    className="popup-choose-inp" 
                                    type="text" 
                                    placeholder="请输入博拉币"
                                    value={bola}
                                    onChange={(e) => this.props.changeInputBola(e,this.props.user.get('balance'))}
                                >
                                    
                                </input>
                        
                                <div className="popup-choose-descbox">
                                        <div className="popup-choose-desc">
                                            当前收益{this.props.user.get('balance')}博拉币，可兑换人民币{(this.props.user.get('balance') / 10).toFixed(2)}元
                                        </div>
                                        <div className="popup-choose-descbtn" onClick={ () => this.props.changeInputBola({target:{value: this.props.user.get('balance')}})}>全部提现</div>
                                </div>
                                     
                                
                                
                            </div>
                            <div className="popup-choose-inpbox">
                                <div className="popup-choose-title">填写信息</div>
                                <div className="popup-choose-itembox">
                                    <div className="popup-choose-itemk">姓名</div>
                                    <input 
                                        className="popup-choose-inpitem" 
                                        type="text" 
                                        placeholder="请输入"
                                        value={name}
                                        onChange={(e) => this.props.changeInputName(e)}
                                    >  
                                    </input>
                                </div>
                              
                                <div className="popup-choose-itembox">
                                    <div className="popup-choose-itemk">银行名称</div>
                                    <input 
                                        className="popup-choose-inpitem" 
                                        type="text" 
                                        placeholder="请输入"
                                        value={bankName}
                                        onChange={(e) => this.props.changeInputBankName(e)}
                                    >
                                    </input>
                                </div>
                                <div className="popup-choose-itembox">
                                    <div className="popup-choose-itemk">银行账号</div>
                                    <input 
                                        className="popup-choose-inpitem" 
                                        type="text" 
                                        placeholder="请输入"
                                        value={bankAccount}
                                        onChange={(e) => this.props.changeInputBankAccount(e)}
                                    >
                                    </input>
                                </div>
                                <div className="popup-choose-itembox">
                                    <div className="popup-choose-itemk" >手机号</div>
                                    <input 
                                        className="popup-choose-inpitem" 
                                        type="text" 
                                        placeholder="请输入"
                                        value={phone}
                                        disabled={true}
                                        onChange={(e) => this.props.changeInputPhone(e)}
                                    ></input>
                                    {
                                        this.state.isFetch
                                            ?  <div className="popup-choose-yzm">{`${this.state.num}s后重新获取`}</div>
                                            :  <div className="popup-choose-yzm" onClick={() => this.handleSendMessage(phone,0)}>获取验证码</div>
                                    }
                                    
                                </div>
                                <div className="popup-choose-itembox">
                                    <div className="popup-choose-itemk">验证码</div>
                                    <input 
                                        className="popup-choose-inpitem" 
                                        type="text" 
                                        placeholder="请输入"
                                        value={code}
                                        onChange={(e) => this.props.changeInputCode(e)}
                                    ></input>
                                </div>
                            </div>
                            <div className="popup-choose-foot">
                                <div className="popup-choose-fbtn" onClick={this.handleWithDraw}>确认提现</div>
                                <div className="popup-choose-fdesc">提示：提现后金额会在3个工作日左右退回您的支付宝账户，请注意查收</div>
                            </div>
                        </div>
                       
                    </div>
               
          
        )
    }
    
}







const mapStateToProps = (state) => {
    return {
        isWithDraw: state.getIn(['personal','isWithDraw']),
        bola: state.getIn(['personal','bola']),
        name: state.getIn(['personal','name']),
        phone: state.getIn(['personal','phone']),
        code: state.getIn(['personal','code']),
        bankName: state.getIn(['personal','bankName']),
        bankAccount: state.getIn(['personal','bankAccount']),
        user: state.getIn(['home','user'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        changeInputBola (e,bola) {
            if (e.target.value > bola) {
                message.warn('不能多提取~')
            } else {
                dispatch(createActions.changeInputBola(e.target.value))
            }
        },
        changeInputName (e) {
            dispatch(createActions.changeInputName(e.target.value))
        },
        changeInputPhone (e) {
            dispatch(createActions.changeInputPhone(e.target.value))
        },
        changeInputCode (e) {
            dispatch(createActions.changeInputCode(e.target.value))
        },
        changeInputBankName (e) {
            dispatch(createActions.changeInputBankName(e.target.value))
        },
        changeInputBankAccount (e) {
            dispatch(createActions.changeInputBankAccount(e.target.value))
        },
        fetchSmsMessage (phone,mode) {
            dispatch(createActions.fetchSmsMessage(phone,mode))
        },
        handleWithDrawMoney (data) {
            dispatch(createActions.handleWithDrawMoney(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(PopupChoose))

