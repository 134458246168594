import {takeLatest,put,select, takeLeading} from 'redux-saga/effects'
import { constants } from '../pages/pay/store'
import * as api from '../api/pay'
import { message } from 'antd';



function* fetchPayLog (data) {
    try {
        const { result } = yield api.fetchPayLog({
                                pageNo: data.page,
                                pageSize: data.pageSize
                            })
        yield put({type: constants.SAVE_PAY_LOG,list: result.records})
    } catch (e) {
        console.log('paySaga: fetchPayLog -> error',e)
    }
}


function* fetchMemberPayLog (data) {
    try {
        const { result } = yield api.fetchMemberPayLog({
                                pageNo: data.page,
                                pageSize: data.pageSize
                           })
        yield put({type: constants.SAVE_PAY_MlOG,list: result.records})
    } catch (e) {
        console.log('paySaga: fetchMemberPayLog -> error',e)
    }
}

function* handleDelPayLog (data) {
    const { id, i } = data
    const state = yield select()
    let logList = state.getIn(['pay','logList']).toJS()
    try {
        yield api.handleDelPayLog({id})
        logList.splice(i,1)
        yield put({type: constants.SAVE_PAY_LOG,list: logList})
        yield put({type: constants.CHANGEPOPSHOW,val: false})
        message.success('删除成功')
        
    } catch (e) {
        console.log('paySaga: handleDelPayLog -> error',e) 
    }
}

function* handleDelMemberPayLog (data) {
    const { id, i } = data
    const state = yield select()
    let memberLogList = state.getIn(['pay','memberLogList']).toJS()
    try {
        yield api.handleDelMemberPayLog({id})
        memberLogList.splice(i,1)
        yield put({type: constants.SAVE_PAY_MlOG,list: memberLogList})
        yield put({type: constants.CHANGEPOPSHOW,val: false})
        message.success('删除成功')
    } catch (e) {
        console.log('paySaga: handleDelMemberPayLog -> error',e) 
    }
}

function* handlePayDelAll () {
    try {
        yield api.handleDelAllPayInfo()
        yield put({type: constants.SAVE_PAY_LOG,list: []})
        yield put({type: constants.CHANGEPOPSHOW,val: false})
    } catch (e) {
        console.log('paySaga: handlePayDelAll -> error',e) 
    }
}

function* handleMemberDelAll () {
    try {
        yield api.handleDelMemberPayLog()
        yield put({type: constants.SAVE_PAY_MlOG,list: []})
        yield put({type: constants.CHANGEPOPSHOW,val: false})
    } catch (e) {
        console.log('paySaga: handleMemberDelAll -> error',e) 
    }
}

function* fetchWithDrawLog (data) {
    try {
        const { result } = yield api.fetchWithDrawLog({pageNo: data.page,pageSize: data.pageSize})
        yield put({type: constants.SAVE_WITHDRAW_LOG, list: result.records})
    } catch (e) {
        console.log('paySaga: fetchWithDrawLog -> error',e) 
    }
}

function* handleDelWithDraw (data) {
    const { id, i } = data
    const state = yield select()
    let withDrawList = state.getIn(['pay','withDrawList']).toJS()   
    try {
        yield api.handleDelWithDraw({id})
        withDrawList.splice(i,1)
        yield put({type: constants.SAVE_WITHDRAW_LOG,list: withDrawList})
        yield put({type: constants.CHANGEPOPSHOW,val: false})
    }  catch (e) {
        console.log('paySaga: handleDelWithDrawLog -> error',e) 
    }
}

function* handleWithDrawDelAll () {
    try {
        yield api.handleMemberLogDelAll()
        yield put({type: constants.SAVE_WITHDRAW_LOG,list: []})
        yield put({type: constants.CHANGEPOPSHOW,val: false})
    } catch (e) {
        console.log('paySaga: handleWithDrawDelAll -> error',e)  
    }
}

//支付宝支付
function* handleZfbNowPay(data) {
    try {
        const { result } = yield api.handleZFBPay(data)
      
        let div = document.querySelector("#payDiv");
        // 如果页面已经存在payDiv，则只要修改payDiv的innerHTML就可以了，否则提交的表单永远是第一个表单。
        if (!div) {
            div = document.createElement('div');
            div.id = "payDiv";
            document.body.appendChild(div);
        }
        div.innerHTML = result;
        document.forms[0].submit();
    } catch (e) {
       console.log('paySaga: handleZfbNowPay -> error',e)   
    }
}

//微信支付
function* handleWxNowPay (data) {
    try {
        const { result } = yield api.handleWxPay(data)
        // var downloadElement = document.createElement('a')
        //  var s =  encodeURI('&redirect_url=http://localhost:3000/#/pay')
        // var s =  '&redirect_url=https%3A%2F%2Fwww.wechatpay.com.cn'
        // var href = result.h5_url
        // downloadElement.href = href
        // document.body.appendChild(downloadElement)
        // downloadElement.click()
        // console.log(result.code_url)
        yield put({type: constants.SAVE_CODE_URL,c: result.code_url})
    } catch (e) {
        console.log('paySaga: handleWxNowPay -> error',e)   
    }
}
//余额支付
function* handleBPayMent (data) {
    try {
        yield api.handleBalancePay(data)
        yield put({type: 'home/FETCH_USER_INFO'})
        message.success('充值成功')
    } catch (e) {
        message.warn(e.message)
        console.log('paySaga: handleBPayMent -> error',e)   
    }
}

//套餐列表
function* fetchGreditList () {
    try {
        const { result } = yield api.fetchGreditList()   
        yield put({type: constants.SAVE_GREDIT_LIST,list: result})
    } catch (e) {
        console.log('paySaga: fetchGreditList -> error',e)   
    }
}

//余额购买套餐
function* handleBPayGredit (data) {
    const { id, password, isOld } = data
    try {
        if (isOld) {
            yield api.handleBalancePayGredit({id,password}) 
            yield put({type: 'home/FETCH_USER_INFO'})
            message.success('充值成功')  
        } else {
            const { result } = yield api.handleCreateMemberOrder({creditId: id})
            yield api.handleBalancePayGredit({id: result.id,password})   
            yield put({type: 'home/FETCH_USER_INFO'})
            message.success('充值成功')
        }
        
    } catch (e) {
        message.warn(e.message)
        console.log('paySaga: handleBPayGredit -> error',e)   
    }
}
//微信购买套餐
function* handleWXPayGredit (data) {
    const { id, type, isOld } = data
    try {
        if (isOld) {
            const res = yield api.handleWxPayGredit({id,type})  
            yield put({type: constants.SAVE_CODE_URL,c: res.result.code_url}) 
        } else {
            const { result } = yield api.handleCreateMemberOrder({creditId: id})
            const res = yield api.handleWxPayGredit({id: result.id,type})  
            yield put({type: constants.SAVE_CODE_URL,c: res.result.code_url}) 
        }
    } catch (e) {
        message.warn(e.message)
        console.log('paySaga: handleBPayGredit -> error',e)   
    }
}

//支付宝购买套餐
function* handleZFBPayGredit (data) {
    const { id, type, returnUrl, isOld } = data
    try {

        if (isOld) {
            const res = yield api.handleZfbPayGredit({id,type, returnUrl})  
            let div = document.querySelector("#payDiv");
            // 如果页面已经存在payDiv，则只要修改payDiv的innerHTML就可以了，否则提交的表单永远是第一个表单。
            if (!div) {
                div = document.createElement('div');
                div.id = "payDiv";
                document.body.appendChild(div);
            }
            div.innerHTML = res.result;
            document.forms[0].submit();
        } else {
            const { result } = yield api.handleCreateMemberOrder({creditId: id})
            const res = yield api.handleZfbPayGredit({id: result.id,type, returnUrl})  
            let div = document.querySelector("#payDiv");
            // 如果页面已经存在payDiv，则只要修改payDiv的innerHTML就可以了，否则提交的表单永远是第一个表单。
            if (!div) {
                div = document.createElement('div');
                div.id = "payDiv";
                document.body.appendChild(div);
            }
            div.innerHTML = res.result;
            document.forms[0].submit();
        }
       
    } catch (e) {
        message.warn(e.message)
        console.log('paySaga: handleBPayGredit -> error',e)   
    }
}



function* paySaga () {
    yield takeLatest(constants.FETCH_PAY_LOG,data => fetchPayLog(data))
    yield takeLatest(constants.FETCH_PAY_MlOG,data => fetchMemberPayLog(data))
    yield takeLatest(constants.DEL_PAY_LOG,data => handleDelPayLog(data))
    yield takeLatest(constants.DEL_PAY_MLOG,data => handleDelMemberPayLog(data))
    yield takeLatest(constants.DEL_ALL_PAY,handlePayDelAll)
    yield takeLatest(constants.DEL_ALL_MEMBER,handleMemberDelAll)
    yield takeLatest(constants.FETCH_WITHDRAW_LOG,data => fetchWithDrawLog(data))
    yield takeLatest(constants.DEL_WITHDRAW_LOG,data => handleDelWithDraw(data))
    yield takeLatest(constants.DEL_ALL_WITHDRAW,handleWithDrawDelAll)
    yield takeLatest(constants.ZFB_PAYMENT,data => handleZfbNowPay(data.data))
    yield takeLatest(constants.WX_PAYMENT,data => handleWxNowPay(data.data))
    yield takeLatest(constants.B_PAYMENT,data => handleBPayMent(data.data))
    yield takeLatest(constants.FETCH_GREDIT_LIST,fetchGreditList)
    yield takeLatest(constants.BALANCE_PAY_GREDIT,data => handleBPayGredit(data.data))
    yield takeLatest(constants.WX_PAY_GREDIT,data => handleWXPayGredit(data.data))
    yield takeLatest(constants.ZFB_PAY_GREDIT,data => handleZFBPayGredit(data.data))
}


export default paySaga