import React,{ useEffect,useState } from 'react'
import {connect} from 'react-redux'
import { withRouter,Link } from 'react-router-dom'
import '../../../static/css/reseale_my.sass'
import delIcon from '../../../static/images/del@red.png'
import { createActions } from '../store'
import { Popconfirm, message } from 'antd';


const MyOpern = (props) => {
    const [page] = useState(1)
    const [pageSize] = useState(10000)
    const { typeInfo,opernList,selected } = props
    
    // var [opernType, setOpernType] = useState(0)
    useEffect(() => {
        // other code
            props.fetchMyOpern(page,pageSize,selected)
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[])
    const handleChangeType = (n) => {
        props.handleChangeSelected(n)
        props.fetchMyOpern(page,pageSize,n)  
    }

    
    return (
        <div className="myopern-box">
            <div className="myopern-nav-list">
                <div className={selected === 0 ? 'myopern-nav-item active' : 'myopern-nav-item'} onClick={() => handleChangeType(0)}>
                    待审核*{typeInfo.get('waitNum')}
                </div>
                <div className={selected === 1 ? 'myopern-nav-item active' : 'myopern-nav-item'} onClick={() => handleChangeType(1)}>
                    已通过*{typeInfo.get('passNum')}
                </div>
                <div className={selected === 2 ? 'myopern-nav-item active' : 'myopern-nav-item'} onClick={() => handleChangeType(2)}>
                    已拒绝*{typeInfo.get('refuseNum')}
                </div>
            </div>
            <div className="myopern-list">
                {
                    opernList.map( (item,i) => (
                        <div className="myopern-list-item" key={item.get('id')}>
                            <Link to={'/reseale/me/opern_detail/' + item.get('id') }>
                                <img  className="myopern-item-pic" src={item.get('cover')} alt="opern img"/>
                            </Link>
                            <div className="myopern-list-item-content">
                                <div className="myoprn-item-top">
                                
                                        <div className="myoprn-item-title">
                                            <Link to={'/reseale/me/opern_detail/' + item.get('id')} className="myoprn-item-link">
                                                {item.get('name')}
                                            </Link>
                                        </div>
                                    
                                    <div className="myoprn-item-price">{item.get('bola') || 0}博拉币</div>
                                </div>
                                <div className="myoprn-item-bottom">
                                    <div className="myoprn-item-date">{item.get('createTime')}</div>
                                    <Popconfirm
                                        title={`你确定要删除曲谱${item.get('name')}吗`}
                                        onConfirm={ () => props.handleDelOpernItem(item.get('id'),i)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <div className="myoprn-item-delbox">
                                            <img className="myoprn-item-delicon" src={delIcon} alt="del icon" />
                                            <div className="myoprn-item-deltext">删除</div>
                                        </div>
                                    </Popconfirm>
                                </div>
                            </div> 
                        </div>
                    ))
                }
            </div>

        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        typeInfo:   state.getIn(['reseale','typeInfo']),
        opernTotal: state.getIn(['reseale','opernTotal']),
        opernList: state.getIn(['reseale','opernList']),
        selected: state.getIn(['reseale','selected'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchMyOpern (page,pageSize,type) {
            dispatch(createActions.fetchMyOpern(page,pageSize,type))
        },
        handleDelOpernItem (id,i) {
            dispatch(createActions.handleDelOpernItem(id,i))
        },
        handleChangeSelected (n) {
            dispatch(createActions.handleChangeSelected(n))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MyOpern))