import request from '../utils/request'


/**
 * 获取充值记录
 */

export function fetchPayLog (params) {
    return request({
        url: '/api/appRecharge/list',
        params
    })
}


/**
 * 会员购买订单
 */

export function fetchMemberPayLog (params) {
    return request({
        url: '/api/appCreditOrder/list',
        params
    })
}

/**
 * Del充值记录
 */
export function handleDelPayLog (params) {
    return request({
        url: '/api/appRecharge/delete',
        method: 'DELETE',
        params
    })
} 

/**
 * Del会员购买记录
 */

export function handleDelMemberPayLog (params) {
    return request({
        url: '/api/appCreditOrder/delete',
        method: 'DELETE',
        params
    })
}

/**
 * 充值记录情空
 */
export function handleDelAllPayInfo () {
    return request({
        url: '/api/appRecharge/deleteAll',
        method: 'DELETE',
    })
}
/**
 * 会员购买记录清空
 */
export function handleMemberLogDelAll () {
    return request({
        url: '/api/appCreditOrder/deleteAll',
        method: 'DELETE',
    })
}


/**
 * 提现记录
 */
export function fetchWithDrawLog (params) {
    return request({
        url: '/api/appAdvance/list',
    })
}

/**
 * 删除提现记录
 */
export function handleDelWithDraw (params) {
    return request({
        url: '/api/appAdvance/delete',
        method: 'DELETE',
        params
    })
}

/**
 * 清空提现记录
 */
export function handleDelAllWithDraw () {
    return request({
        url: '/api/appAdvance/deleteAll',
        method: 'DELETE',
    })
}

/**
 * 支付宝充值
 */
export function handleZFBPay (data) {
    return request({
        url: '/api/appUser/aliPcRecharge',
        method: 'post',
        data,
        json: true
    })
}

/**
 * 微信充值
 */
export function handleWxPay (data) {
    return request({
        url: '/api/appUser/wxRecharge',
        method: 'post',
        data,
        json: true
    })
}

/**
 * 余额充值
 */
export function handleBalancePay (data) {
    return request({
        url: '/api/appUser/balancePay',
        method: 'post',
        data,
        json: true
    })
}

/**
 * 套餐列表
 */
export function fetchGreditList () {
    return request({
        url: '/api/appCredit/list'
    })
}



/**
 * 会员创建订单
 */
export function handleCreateMemberOrder (data) {
    return request({
        url: '/api/appCreditOrder/addOrder',
        method: 'post',
        data,
        json: true
    })
}

/**
 * 会员订单支付(余额)
 */
export function handleBalancePayGredit (data) {
    return request({
        url: '/api/appCreditOrder/balancePay',
        method: 'post',
        data,
        json: true
    })
}


/**
 * 会员购买微信
 */
export function handleWxPayGredit (data) {
    return request({
        url: '/api/appCreditOrder/wxPay',
        method: 'post',
        data,
        json: true
    })
}

/**
 * 会员购买支付宝
 */
export function handleZfbPayGredit (data) {
    return request({
        url: '/api/appCreditOrder/aliPay',
        method: 'post',
        data,
        json: true
    })
}