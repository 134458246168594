import request from '../utils/request'


/**
 * 获取登录二维码
 * @returns 
 */
 export function fetchQrCode () {
    return request({
        url: '/api/getCode',
    })
 }

 /**
 * 获取登录二维码
 * @param {string} code   二维码code
 * @returns  
 */

 export function handleIsLogin (params) {
    return request({
        url: '/api/getToken',
        params
    })
 }