import "../static/css/paymode.sass"
import balanceIcon from '../static/images/balance.png'
import wxIcon from '../static/images/wx.png'
import zfbIcon from '../static/images/zfb.png'

const Paymode = (props) => {
    return (
        <div className="paymode-box">
            <div className="paymode-title">支付方式</div>
            <div className="paymode-list">
                <div  className={props.mode === 1 ? `paymode-item active` : 'paymode-item'} onClick={() => props.handleChangePayMode(1)}>
                    <img src={wxIcon} alt="wx icon" />
                    <span className="paymode-text">微信</span>
                </div>
                <div  className={props.mode === 2 ? `paymode-item active` : 'paymode-item'} onClick={() => props.handleChangePayMode(2)}>
                    <img src={zfbIcon} alt="zfb icon" />
                    <span className="paymode-text">支付宝</span>
                </div>
                <div className={props.mode === 3 ? `paymode-item-one active` : 'paymode-item-one'} onClick={() => props.handleChangePayMode(3)}>
                    <div className="paymode-top">
                        <div className="balance-bg">
                            <img src={balanceIcon} alt="ye icon" className="balance-icon"/>
                        </div> 
                        <span className="paymode-text">余额支付</span>
                    </div>
                    {
                      props.b
                        ?  <div className="paymode-desc">(可用余额{props.b / 10}.00元)</div> 
                        : null
                    }
                </div>
            </div>
        </div>
    )
}


export default Paymode