import {takeLatest,put,select} from 'redux-saga/effects'
import { constants } from '../pages/login/store'
import * as api from '../api/login'
import { message } from 'antd';

function* fetchLoginCode () {
    try {
        const response = yield api.fetchQrCode()
        yield put({type: constants.SAVE_LOGIN_CODE,code: response.result}) 
    } catch (e) {
        console.log('loginSaga: fetchLoginCode -> error',e)
    }
}

function* handleIsLogin () {
    const state = yield select()
    try {
        const response = yield api.handleIsLogin({code: state.getIn(['login','code'])})
        localStorage.setItem('token',response.result)
        yield put({type: constants.SAVE_LOGIN_TOKEN})
        message.success('登录成功')
        window.location.reload(true);
       
    } catch (e) {
        console.log('loginSaga: handleIsLogin -> error',e)
    }
}

function* loginSaga () {
  yield takeLatest(constants.FETCH_LOGIN_CODE,fetchLoginCode)
  yield takeLatest(constants.USER_IS_LOGIN,handleIsLogin)  
}

export default loginSaga