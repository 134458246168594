import React,{ useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/pay_log.sass'
import { createActions } from '../store'
import Table from '../../../common/table'
import Popup from '../../../common/popup'

const Log = (props) => {
    const { 
        depositLog,
        handleDepositLog,
        tableHeadLog,
        tableHeadMember,
        popupShow,
        handlePopShow,
        handleClosePopShow,
        handlePopShowOK,
        logList,
        memberLogList 
    } = props
    useEffect(() => {
        // other code
        props.fetchPayLog()
        props.fetchPayMemberLog()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])
    return (
        <div className="log-box">
            <div className="log-nav">
                <div  className={depositLog === 1 ? 'active log-nav-item' : 'log-nav-item'} onClick={() => handleDepositLog(1)}>点券充值记录</div>
                <div  className={depositLog === 2 ? 'active log-nav-item' : 'log-nav-item'} onClick={() => handleDepositLog(2)}>会员充值记录</div>
            </div>
            {
                depositLog === 1
                    ?  <Table 
                            thead={tableHeadLog} 
                            tbody={logList}
                            delLog={handlePopShow}
                            i={depositLog}
                            with={[]}
                        > 
                        </Table>
                    :    <Table 
                            thead={tableHeadMember} 
                            tbody={memberLogList}
                            delLog={handlePopShow}
                            i={depositLog}
                            with={[]}
                        > 
                        </Table>
            }
            <Popup dialogVisible={popupShow} close={handleClosePopShow} confrim={() => handlePopShowOK(props.itemId,depositLog)}></Popup>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        depositLog: state.getIn(['pay','depositLog']),
        tableHeadLog: state.getIn(['pay','tableHeadLog']),
        tableHeadMember: state.getIn(['pay','tableHeadMember']),
        popupShow: state.getIn(['pay','popupShow']),
        logList: state.getIn(['pay','logList']),
        memberLogList: state.getIn(['pay','memberLogList']),
        itemId: state.getIn(['pay','itemId'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
      handleDepositLog (n) {
        dispatch(createActions.changeDepositLog(n))
      },
      handlePopShow (mode,id,i) {
        dispatch(createActions.changePopShow(true))
        dispatch(createActions.handleSaveItemId(`${mode}_${id}_${i}`))
      },
      handleClosePopShow () {
        dispatch(createActions.changePopShow(false))
      },
      handlePopShowOK (data,t) {
         let [d,id,i] = data.split('_')
         console.log(t)
         if (t === 1) {
            if (d === 'del') {
                dispatch(createActions.handleDelPayLog(id,i))
            } else {
                dispatch(createActions.handlePayDelAll())
            }
         } else {
            if (d === 'del') {
                dispatch(createActions.handleDelMemberPayLog(id,i))
            } else {
                dispatch(createActions.handleMemberDelAll())
            }
         }
      },
      fetchPayLog () {
         dispatch(createActions.fetchPayLog(1,50)) 
      },
      fetchPayMemberLog () {
          dispatch(createActions.fetchPayMemberLog(1,50))
      }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Log))
