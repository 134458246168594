import { constants } from './index'


export const changePayMode = (n) => ({
    type: constants.CHANGEPAYMODE,
    n
})

export const changeFast = (n) => ({
    type: constants.CHANGEFAST,
    n
})

export const changeOther = (val) => ({
    type: constants.CHANGEOTHER,
    val
})

export const changeDepositLog = (n) => ({
    type: constants.CHANGEDEPOSITLOG,
    n
})

export const changePopShow = (val) => ({
    type: constants.CHANGEPOPSHOW,
    val
})


export const fetchPayLog = (page,pageSize) => ({
    type: constants.FETCH_PAY_LOG,
    page,
    pageSize
})

export const fetchPayMemberLog = (page,pageSize) => ({
    type: constants.FETCH_PAY_MlOG,
    page,
    pageSize
})

export const handleSaveItemId = (id) => ({
    type: constants.SAVE_ITEM_ID,
    id
})

export const handleDelPayLog = (id,i) => ({
    type: constants.DEL_PAY_LOG,
    id,
    i
})

export const handleDelMemberPayLog = (id,i) => ({
    type: constants.DEL_PAY_MLOG,
    id,
    i
})

export const handlePayDelAll = () => ({
    type: constants.DEL_ALL_PAY
})

export const handleMemberDelAll = () => ({
    type: constants.DEL_ALL_MEMBER
})

export const fetchWithDrawLog = (page,pageSize) => ({
    type: constants.FETCH_WITHDRAW_LOG,
    page,
    pageSize
})


export const handleDelWithDrawLog = (id,i) => ({
    type: constants.DEL_WITHDRAW_LOG,
    id,
    i
})

export const handleWithDrawDelAll = () => ({
    type: constants.DEL_ALL_WITHDRAW
})

export const handleZfbPayMent = (data) => ({
    type: constants.ZFB_PAYMENT,
    data
}) 

export const handleSetMemberFast = (val) => ({
    type: constants.SET_MEMBER_FAST,
    val
})

export const handleWxPayMent = (data) => ({
    type: constants.WX_PAYMENT,
    data
})

export const handleBPayMent = (data) => ({
    type: constants.B_PAYMENT,
    data
})

export const fetchGreditList = () => ({
    type: constants.FETCH_GREDIT_LIST
}) 

export const handleBalancePayGredit = (data) => ({
    type: constants.BALANCE_PAY_GREDIT,
    data
})

export const handleSaveCodeUrl = (c) => ({
    type: constants.SAVE_CODE_URL,
    c
})


export const handleWXPayGredit = (data) => ({
    type: constants.WX_PAY_GREDIT,
    data
})

export const handleZFBPayGredit = (data) => ({
    type: constants.ZFB_PAY_GREDIT,
    data
})