import React,{ useState } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/reseale_opern.sass'
import OpenrForm from '../../../common/opernform'
// import Uploads from '../../../common/uploads'
import { createActions } from '../store'
// import { createActions } from '../../store'



const Opern = (props) => {
    const { opernForm,handleAddOpern } = props
    const [isShowOpern, setIsShowOpern] = useState(1)
    const handleChangeWindow = (n) => {
        setIsShowOpern(n)
    }
    return (
        <div className="opern-wrap">
            <div className="opern-title">发布曲谱</div>
            <div className="opern-content">
                {/* <Uploads musicData={opernForm} showNo={isShowOpern} changeWindow={handleChangeWindow}></Uploads> */}
                <OpenrForm musicData={opernForm} showNo={isShowOpern} changeWindow={handleChangeWindow} add={handleAddOpern}></OpenrForm>
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        opernForm: state.getIn(['reseale','opernForm']),
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleAddOpern (data) {
            dispatch(createActions.handleAddOpern(data))
        } 
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Opern))