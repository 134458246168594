import {takeLatest,put} from 'redux-saga/effects'
import { constants } from '../pages/home/store'
import * as api from '../api/home'



function* fetchUserInfo() {
    try {
        const { result } = yield api.fetchUserInfo()
        localStorage.setItem('user',JSON.stringify(result))
        yield put({type: constants.SAVE_USER_INFO})
        yield put({type: constants.FETCH_USER_AUTH, t: result.id})
    } catch (e) {
        console.log('homeSaga: fetchUserInfo -> error',e)
    }
}


function* fetchUserAuth(t) {
    console.log(t)
  //  console.log(t,1111111111)
    try {
       const { result } = yield api.fetchUserAuth({id: t.t}) 
       localStorage.setItem('info',JSON.stringify(result))
       yield put({type: constants.SAVE_USER_AUTH})
    } catch (e) {
        console.log('homeSaga: fetchUserAuth -> error',e)
    }
}



function* homeSaga () {
    yield takeLatest(constants.FETCH_USER_INFO,fetchUserInfo)
    yield takeLatest(constants.FETCH_USER_AUTH,data => fetchUserAuth(data))
}


export default homeSaga