import "../static/css/popup.sass"
import rmIcon from '../static/images/close.png'
import { CSSTransition } from 'react-transition-group'
import '../static/css/animation.css'
import { Checkbox  } from 'antd'

function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
}
const PopupChoose = (props) => {
    const dialogVisible = false
    return (
        <div className="popup-container">
            <CSSTransition
                in={dialogVisible}
                timeout={200}
                classNames="login"
            >
                <div className="popup-box">
                    <div className="popup-cover"></div>
                    <div className="popup-choose">
                        <div className="popup-choose-title">
                            <div className="popup-choose-title-text">添加视频</div>
                            <img className="del-icon" src={rmIcon}  alt="remove icon"/>
                        </div>
                        <div className="popup-choose-inpbox">
                            <input className="popup-choose-inp" type="text" placeholder="输入关键字搜索"></input>
                        </div>
                        <div className="selected-list">
                            <div className="selected-item">
                                <div className="selected-item-text">短视频名称1</div>
                                <img className="selected-del-icon" src={rmIcon}  alt="remove icon"/>
                            </div>
                        </div>
                        <div className="popup-choose-option">
                            <Checkbox.Group  onChange={onChange} className="p-choose-list">
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                                    <div className="p-choose-item">
                                        <div className="p-choose-item-text">短视频1名称</div>
                                        <Checkbox value="A"></Checkbox>
                                    </div>
                            </Checkbox.Group>
                        </div>

                        <div className="popup-choose-btnbox">
                            <div className="popup-choose-btn">确认添加</div>
                        </div>
                    </div>

                </div>
            </CSSTransition>
        </div>
    )
}








export default PopupChoose