import React, { Component } from 'react';
import { DatePicker } from 'antd';
import "../static/css/line.sass"
import ReactEcharts from 'echarts-for-react';
import { connect } from 'react-redux'
import { createActions } from "../pages/personal/store"
import { withRouter } from 'react-router-dom'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';

function getNowDate() {
    var date = new Date();
    var year = date.getFullYear() // 年
    var month = date.getMonth() + 1; // 月
    var day  = date.getDate(); // 日
   
    // 给一位数数据前面加 “0”
    if (month >= 1 && month <= 9) {
     month = "0" + month;
    }
    if (day >= 0 && day <= 9) {
        day = "0" + day;
    }
    return `${year}-${month}-${day}`
}

function getPastDate () {
    var date = new Date();
    date.setDate(date.getDate() - 7);
    var year = date.getFullYear() // 年
    var month = date.getMonth() + 1; // 月
    var day  = date.getDate(); // 日
   
    // 给一位数数据前面加 “0”
    if (month >= 1 && month <= 9) {
     month = "0" + month;
    }
    if (day >= 0 && day <= 9) {
        day = "0" + day;
    }
    return `${year}-${month}-${day}`
}


class EchartsBar extends Component {
    constructor(props){
        super(props);
        this.state={
            start: getPastDate(),
            end: getNowDate()
        };
        this.handleDateChange = this.handleDateChange.bind(this)
    }
    handleDateChange (m,s) {
        console.log(m)
        console.log(s)
        this.setState({
            start: s[0],
            end: s[1]
        },() => {
            this.props.fetchOrder({startDate: this.state.start,endDate: this.state.end})
        })
    }
    /**
     * @description 配置图表
     * @returns 
     * @memberof EchartsRadar
     */
    getOption(){
        let arr = this.props.orderNum.toJS()
        let x = []
        let y1 = []
        let y2 = []
        if (arr.courseScoreList) {
            arr.courseScoreList.forEach((item) => {
                x.push(item.createTime)
            }) 
            arr.musicScoreList.forEach(item => {
                x.push(item.createTime)
            })
            x = [...new Set(x)].sort((n1,n2) => n1 > n2 ? 1 : -1)
            x.forEach(item => {
                let i = arr.courseScoreList.filter(y => y.createTime === item)
                let j = arr.musicScoreList.filter(y => y.createTime === item)
                i[0]
                    ? y1.push(i[0].total)
                    : y1.push(0)  
                j[0]
                    ? y2.push(j[0].total)
                    : y2.push(0)  
            })
        }
         return {
           
            color: ['#67D2FF','#FFD25A'],
            xAxis: {
                type: 'category',
                data: x,
                axisTick: {
                    show: false
                },
                offset: 8
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow"
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: y2,
                name: '曲谱成交数量',
                type: 'bar',
                barWidth: 8,
                itemStyle: {
                    normal: {
                        barBorderRadius: [4,4,0,0]
                    }
                },
                smooth: true
            },{
                data: y1,
                name: '微课成交数量',
                type: 'bar',
                barWidth: 8,
                itemStyle: {
                    normal: {
                      barBorderRadius: [4,4,0,0]
                    }
                },
                smooth: true
            }]
        };
    }
    /**
     * @description 点击事件和外部显示标签点击事件
     * @param {any} param 
     * @param {any} echarts 
     * @memberof EchartsRadar
     */
    onChartClick(param,echarts){
        console.log(param)
    }
    /**
     * @description 点击legend事件
     * @param {any} param 
     * @param {any} echarts 
     * @memberof EchartsRadar
     */
    onChartLegendselectchanged(param,echarts){
        console.log(param)
    }
    componentDidMount(){
        this.props.fetchOrder({startDate: this.state.start,endDate: this.state.end})
    }
    render(){
        let onEvents={
            'click': this.onChartClick.bind(this),
            'legendselectchanged': this.onChartLegendselectchanged.bind(this)
        }
        return(
            <div className="echartsRadar">
                <div className="echarts-top">
                    <div className="echarts-left">
                        <div className="echarts-item">
                            <div className="echarts-tag"></div>
                            <div className="echarts-name">曲谱成交数量</div>
                        </div>
                        <div className="echarts-item">
                            <div className="echarts-tag"></div>
                            <div className="echarts-name">微课成交数量</div>
                        </div>
                    </div>
                    <div className="echarts-right">
                        <DatePicker.RangePicker 
                            inputReadOnly 
                            locale={locale}
                            defaultValue={[moment(`${this.state.start}`, dateFormat), moment(`${this.state.end}`, dateFormat)]}
                            format={dateFormat}
                            onChange={this.handleDateChange}
                        > 
                        </DatePicker.RangePicker >
                    </div>
                </div>
                <ReactEcharts
                    option={this.getOption()} 
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                    style={{width: '100%',height:'100%'}}
                />
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        orderNum: state.getIn(['personal','orderNum'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrder (params) {
            dispatch(createActions.fetchOrder(params))
        } 
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(EchartsBar))