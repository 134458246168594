import React,{ useState, useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/pay_deposit.sass'
import Paymode from '../../../common/paymode.js'
import { createActions } from '../store'
import { message, Modal, Drawer } from 'antd';
import QRCode from 'qrcode.react'
import wxIcon from '../../../static/images/wx.png'


const Member = (props) => {
    const [bola,setBola] = useState({})
    const [isModalVisible, setIsModalVisible] =  useState(false)
    const [val, setVal] = useState('')

    const handlePayment = () => {
        const { mode,memberFast } = props
        console.log(mode)
        if (mode !== 1 && mode !== 2 && mode !== 3) {
            return message.warn('请选择支付方式')
        } 
        if (memberFast !== 0 && memberFast !== 1 && memberFast !== 2) {
            return message.warn('请选择支付套餐')
        } 
        if (mode === 1) {
            return props.handleWXPayGredit({
                id: bola.id,
                type: 'qrcode'
            })
        }
        if (mode === 2) {
            return props.handleZFBPayGredit({
                id: bola.id,
                type: 'h5',
                returnUrl: '/html/#/pay'
            })
        }
        if (mode === 3) {
            setIsModalVisible(true)
        }
    }


    const handleChangeMemberFast = (i,p) => {
        setBola(p)
        props.handleChangeMemberFast(i)
    } 
    const handleOk = () => {
        if (!val) return message.warn('请输入支付密码')
        props.handleBalancePayGredit({
            id: bola.id,
            password: val
        })
        setVal('')
        setIsModalVisible(false)  
    }
    const handleCancel = () => {
        setIsModalVisible(false)       
    }
    const handleInpChange = (e) => {
        setVal(e.target.value)
    }

    const onClose = () => {
        props.handleSaveCodeUrl('')
    }

    useEffect(() => {
        // other code
            props.fetchGreditList()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    return (
        <div className="deposit-box">
            <Modal title="支付密码" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消">
                <input type='password' style={{width: '100%',borderRadius: '20px', padding: '10px 20px',border: '1px solid #dedede'}} placeholder="请输入支付密码" value={val} onChange={handleInpChange}/>
            </Modal>
            <Drawer title="微信扫码支付" placement="bottom" onClose={onClose} visible={props.codeUrl}  height={500}>
                  <div className='wx-pay-box'>
                        
                        <QRCode size={250} fgColor='#000000' value={props.codeUrl}></QRCode>
                        <div className='wx-pay-b'>
                            <img className='wx-pay-icon' src={wxIcon}></img>
                            <span className='wx-pay-t'>￥{(+bola.price).toFixed(2)}</span>
                        </div>
                  </div>
            </Drawer>
            <div className="deposit-head">
                会员中心
            </div>
            <div className="deposit-content">
                <Paymode  b={props.user ? props.user.get('balance') : 0} mode={props.mode} handleChangePayMode={props.handleChangePayMode}></Paymode>
                <div className="bola-box">
                    <div className="bola-title">
                        <span className="bola-title-text">会员中心</span>
                        <span className="bola-title-desc">(开通VIP，享受更多权益)</span>
                    </div>
                    <div className="bola-list">
                        {
                            props.greditList.map( (item,index) => (
                                <div  
                                    className={props.memberFast === index ? 'bola-item active' : 'bola-item'} 
                                    onClick={() => handleChangeMemberFast(index,item)}
                                    key={item.id}
                                >
                                    <div className="member-item-title">{item.name}</div>
                                    <div className="member-money">￥<span className="member-num">{item.price}</span>/<span className="member-unit">月</span></div>
                                </div>
                            ))
                        }
                       
                    </div> 
                   
                </div>
                <div className="deposit-btn-box">
                    <div className="deposit-btn" onClick={handlePayment}>立即支付</div>
                    <div className="deposit-btn-desc">提示：购买成功后，不可退款</div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
       mode: state.getIn(['pay','mode']),
       memberFast: state.getIn(['pay','memberFast']),
       user: state.getIn(['home','user']),
       greditList: state.getIn(['pay','greditList']),
       codeUrl: state.getIn(['pay','codeUrl'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleChangePayMode (n) {
            dispatch(createActions.changePayMode(n))
        },
        handleZfbPayment (data) {
            dispatch(createActions.handleZfbPayMent(data))
        },
        handleChangeMemberFast (val) {
            dispatch(createActions.handleSetMemberFast(val))
        },
        fetchGreditList () {
            dispatch(createActions.fetchGreditList())
        },
        handleBalancePayGredit (data) {
            dispatch(createActions.handleBalancePayGredit(data))
        },
        handleWXPayGredit (data) {
            dispatch(createActions.handleWXPayGredit(data))
        },
        handleZFBPayGredit (data) {
            dispatch(createActions.handleZFBPayGredit(data))
        },
        handleSaveCodeUrl (v) {
            dispatch(createActions.handleSaveCodeUrl(v))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Member))