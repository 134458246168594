import {takeLatest,put,select} from 'redux-saga/effects'
import { constants } from '../pages/reseale/store'
import * as api from '../api/reseale'
import { message } from 'antd'
// import { message } from 'antd';



//我的曲谱列表
function* fetchMyOpern (data) {
   const { page, pageSize, t} = data
   try {
       const { result } = yield api.fetchOpern({status: t, pageNo: page,pageSize})
       yield put({type: constants.SAVE_TYPE_INFO,info: {passNum: result.passNum,refuseNum: result.refuseNum,waitNum: result.waitNum}})
       yield put({type: constants.SAVE_LIST_TOTAL,total: result.data.total})
       yield put({type: constants.SAVE_MY_OPERN,list: result.data.records})
   } catch (e) {
       console.log('resealeSaga: fetchMyOpern -> error',e)
   }
} 
//我的微课列表
function* fetchMyMicro (data) {
   const { page, pageSize, t} = data
   try {
       const { result } = yield api.fetchMicro({status: t, pageNo: page,pageSize})
       yield put({type: constants.SAVE_TYPE_INFO,info: {passNum: result.passNum,refuseNum: result.refuseNum,waitNum: result.waitNum}})
       yield put({type: constants.SAVE_LIST_TOTAL,total: result.data.total})
       yield put({type: constants.SAVE_MY_OPERN,list: result.data.records})
   } catch (e) {
       console.log('resealeSaga: fetchMyMicro -> error',e)
   }

}
//曲谱详情
function* fetchOpernDetail (id) {
    try {
        const { result } = yield api.fetchOpernDetail({id})
        yield put({type: constants.SAVE_OPERN_DETAIL,info: result})
    } catch (e) {
        console.log('resealeSaga: fetchOpernDetail -> error',e)
    }
}
//曲谱类型
function* fetchOpernType(v) {
    try {
        const { result } = yield api.fetchOpernType({type: v})
        yield put({type: constants.SAVE_OPERN_TYPE,list: result})
    } catch (e) {
        console.log('resealeSaga: fetchOpernDetail -> error',e)
    }
}

//我的短视频
function* fetchMyVideo (data) {
    const { page, pageSize, t} = data
    try {
        const { result } = yield api.fetchMyVideo({status: t, pageNo: page,pageSize})
        yield put({type: constants.SAVE_MY_VIDEO,list: result.data.records})
    } catch (e) {
        console.log('resealeSaga: fetchMyVideo -> error',e)
    }
}

//添加曲谱  
function* handlePlusMyOpern (data) {
    try {
        yield api.handlePlusOpern(data)
        message.success('添加成功')
        setTimeout(() => {
            window.location.reload(true);  
        },5000)
    } catch(e) {
        console.log('resealeSaga: handlePlusMyOpern -> error',e)
    }
 }

//添加微课
function* handlePlusMyMicro (data) {
    try {
        yield api.handlePlusMicro(data)
        message.success('添加成功')
        // setTimeout(() => {
        //     window.location.reload(true);
        // },5000)
    } catch(e) {
        console.log('resealeSaga: handlePlusMyMicro -> error',e)
    }
 }

 //删除曲谱
 function* handleDelOpern (id,i) {
     const state = yield select()
     let list = state.getIn(['reseale','opernList']).toJS()
    
    try {
        yield api.handleDelOpernItem({id})
        list.splice(i,1)
        yield put({type: constants.SAVE_MY_OPERN,list})
    } catch (e) {
        console.log('resealeSaga: handleDelOpern -> error',e)
    }
 }

 //微课详情
 function* fetchMicroDetail (id) {
    try {
        const { result } = yield api.fetchMicroDetail({id})
        yield put({type: constants.SAVE_OPERN_DETAIL,info: result})
    } catch (e) {
        console.log('resealeSaga: fetchMicroDetail -> error',e)
    }
 }
 
 //获取评论
 function* fetchCommitList (name,id) {
     try {
         const { result } = yield api.fetchCommit({
                                tableName: name,
                                sourceId: id,
                                pageNo: 1,
                                pageSize: 9999
                            })
         yield put({type: constants.SAVE_COMMIT_LIST,list: result.records})
     } catch (e) {
        console.log('resealeSaga: fetchCommitList -> error',e) 
     }
 }
 

function* resealeSaga () {
    yield takeLatest(constants.FETCH_MY_OPERN,data => fetchMyOpern(data))
    yield takeLatest(constants.FETCH_MY_MICRO,data => fetchMyMicro(data))
    yield takeLatest(constants.FETCH_OPERN_DETAIL,data => fetchOpernDetail(data.id))
    yield takeLatest(constants.FETCH_OPERN_TYPE,data => fetchOpernType(data.val))
    yield takeLatest(constants.FETCH_MY_VIDEO,data => fetchMyVideo(data))
    yield takeLatest(constants.ADD_MY_OPERN,data => handlePlusMyOpern(data.data))
    yield takeLatest(constants.ADD_MY_MICRO,data => handlePlusMyMicro(data.data))
    yield takeLatest(constants.DEL_OPERN_ITEM,data => handleDelOpern(data.id,data.i))
    yield takeLatest(constants.FETCH_MICRO_DETAIL,data => fetchMicroDetail(data.id))
    yield takeLatest(constants.FETCH_COMMIT_LIST,data => fetchCommitList(data.name,data.id))
}


export default resealeSaga