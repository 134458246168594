import { constants } from './index'
import { fromJS } from 'immutable'
const defaultState = fromJS({
   selected: 0,
   selectedA: 0,
   opernTotal: 1,     //我的曲谱列表count
   typeInfo: {        //我的曲谱列表不同类型num
    waitNum: 0,
    passNum: 0,
    refuseNum: 0
   },
   opernList: [],     //曲谱列表
   detailInfo: {},    //详情info
   detailSelect: 1,
   opernForm: [{id: 1}], //发布曲谱
   opernTypeList: [], //曲谱类型
   myVideoList: [], //我的短视频
   microForm: [{id: 1}], //发布微课
   commitList: []
})

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_DETAIL_SELECTED: 
      return state.set('detailSelect',action.val)
    case constants.SAVE_TYPE_INFO: 
      return state.set('typeInfo',fromJS(action.info))
    case constants.SAVE_LIST_TOTAL: 
      return state.set('opernTotal',fromJS(action.total))
    case constants.SAVE_MY_OPERN: 
      return state.set('opernList',fromJS(action.list))
    case constants.SAVE_OPERN_DETAIL:
      return state.set('detailInfo',fromJS(action.info))
    case constants.ADD_OPERN_FORM: 
      return state.set('opernForm',fromJS(action.data))
    case constants.ADD_MICRO_FORM: 
      return state.set('microForm',fromJS(action.data))
    case constants.SAVE_OPERN_TYPE: 
      return state.set('opernTypeList',fromJS(action.list))
    case constants.SAVE_MY_VIDEO: 
      return state.set('myVideoList',fromJS(action.list))
    case constants.DEL_OPERN_FORM: 
      return state.set('opernForm',fromJS(action.data))
    case constants.DEL_MICRO_FORM: 
      return state.set('microForm',fromJS(action.data))
    case constants.SAVE_OPERN_DETAIL: 
      return state.set('detailInfo',fromJS(action.info))
    case constants.SAVE_COMMIT_LIST:
      return state.set('commitList',fromJS(action.list))
    case constants.CHANGE_SELECTRD:
      return state.set('selected',action.n)
    case constants.CHANGE_SELECTRD_A: 
      return state.set('selectedA',action.n)
    default: return state
  }
}

export default reducer