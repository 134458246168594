
import "../static/css/table.sass"
import rmIcon from '../static/images/del@red.png'
import { createActions } from '../pages/pay/store'
import React,{ useState } from 'react'
import { connect } from 'react-redux'
import { message, Modal, Drawer } from 'antd'
import wxIcon from '../static/images/wx.png'
import QRCode from 'qrcode.react'

const Table = (props) => {
    const { i } = props
    const setPayText = (n) => {
        if (n === 1) {
            return '余额'
        }
        if (n === 2) {
            return '支付宝'
        }
        if (n === 3) {
            return '微信'
        }
    }
    const withText = (n) => {
        if (n === 0) {
            return '待审核'
        }
        if (n === 1) {
            return '待处理'
        }
        if (n === 2) {
            return '已处理'
        }
        if (n === 3) {
            return '拒绝'
        }
    }

    const [isModalVisible, setIsModalVisible] =  useState(false)
    const [val, setVal] = useState('')
    const [now, setNow] = useState({})

    const handleOk = () => {
        if (!val) return message.warn('请输入支付密码')
        if (now.type) {
            props.handleBPayMent({
                id: now.id,
                password: val
            })
        } else {
            props.handleBalancePayGredit({
                id: now.id,
                password: val,
                isOld: true
            })
        }
       
        setVal('')
        setIsModalVisible(false)  
    }
    const handleCancel = () => {
        setIsModalVisible(false)       
    }
    const handleInpChange = (e) => {
        setVal(e.target.value)
    }
    const onClose = () => {
        props.handleSaveCodeUrl('')
    }

    
    const handleOrderPay = (i,item) => {
        const d = item.toJS()
        console.log(d)
        if (i === 1) {
            //1 余额   2支付宝  3微信
            if (d.type === 1) {
                setNow(d)
                setIsModalVisible(true)
            }
            if (d.type === 2) {
                setNow(d)
                props.handleZfbPayMent({
                    id: d.id,
                    returnUrl: '/html/#/pay/log'
                })
            }
            if (d.type === 3) {
                setNow(d)
                props.handleWxPayMent({
                    id: d.id,
                    type: 'qrcode'
                })
            }
        } else {
            //1余额，2支付宝，3微信
            if (d.payType === 1) {
                setNow(d)
                setIsModalVisible(true)
            }
            if (d.payType === 2) {
                setNow(d)
                props.handleZFBPayGredit({
                    id: d.id,
                    type: 'h5',
                    returnUrl: '/#/pay/log',
                    isOld: true
                })
            }
            if (d.payType === 3) {
                setNow(d)
                props.handleWXPayGredit({
                    id: d.id,
                    type: 'qrcode',
                    isOld: true
                })
            }
        }
    }

    return (
        <div>
           <Modal title="支付密码" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消">
                <input 
                    type='password' 
                    style={{width: '100%',borderRadius: '20px', padding: '10px 20px',border: '1px solid #dedede'}} 
                    placeholder="请输入支付密码" value={val} onChange={handleInpChange}
                />
            </Modal>
            <Drawer title="微信扫码支付" placement="bottom" onClose={onClose} visible={props.codeUrl}  height={500}>
                  <div className='wx-pay-box'>
                        
                        <QRCode size={250} fgColor='#000000' value={props.codeUrl}></QRCode>
                        <div className='wx-pay-b'>
                            <img className='wx-pay-icon' src={wxIcon}></img>
                            <span className='wx-pay-t'>{now.name}</span>
                        </div>
                  </div>
            </Drawer>
            <table className="table-box" width="100%" align="center">
                <thead className="table-header" align="center" valign="middle">
                    <tr className="table-header-row">
                        {
                            props.thead 
                                ?  props.thead.map((item,index) => 
                                        <td className="table-header-col" key={index} >
                                            {item.get('name')}
                                            {
                                                item.get('unit')
                                                    ? <span className="table-unit">({item.get('unit')})</span>
                                                    : null
                                            }
                                        </td>
                                )
                                : null
                        }
                    </tr>
                </thead>
                <tbody className="table-body">
                    {
                        props.tbody.map((item,index) => 
                            (
                                <tr className="table-body-row" key={item.get('id')}>
                                    <td className='table-body-col' >
                                        {item.get('createTime')}
                                    </td>
                                    {
                                        i === 2
                                            ?   <td className='table-body-col' >
                                                    {item.get('name')}  ￥{item.get('price')}
                                                </td>
                                            : null
                                    }
                                    {
                                        i === 1
                                            ?   <td className='table-body-col' >
                                                    {setPayText(item.get('type'))}
                                                </td>
                                            :   null
                                    }
                                    {
                                        i === 2
                                            ?   <td className='table-body-col' >
                                                    {setPayText(item.get('payType'))}
                                                </td>
                                            :   null
                                    }
                                   
                                    {
                                        i === 1
                                            ? <td className='table-body-col' >
                                                {item.get('money')}
                                              </td>
                                            : null
                                    }
                                    {
                                        i === 1
                                            ? <td className='table-body-col' >
                                                 {item.get('bola')}
                                              </td>
                                            : null
                                    }
                                    {
                                       item.get('payStatus') === 0 
                                            ? <td className='table-body-col blue'>
                                                    未支付
                                              </td>
                                            : item.get('payStatus') === 1
                                                ? <td className='table-body-col'>
                                                        已支付
                                                  </td>
                                                :  <td className='table-body-col red'>
                                                        关闭
                                                   </td>
                                    }
                                    {
                                      item.get('payStatus') === 0
                                          ? <td className='table-body-col yellow' onClick={() => handleOrderPay(i,item)}>
                                                继续支付
                                            </td>
                                          : <td className='table-body-col red' onClick={ () => props.delLog('del',item.get('id'),index)}>
                                                删除
                                            </td>  
                                    }
                                </tr>
                            ))
                    }
                    {
                       props.with.map((item,index) => (
                            <tr className="table-body-row" key={item.get('id')}>
                                <td className='table-body-col' >
                                    {item.get('createTime')}
                                </td>
                                <td className='table-body-col' >
                                    {item.get('bankAccount')}
                                </td>
                                <td className='table-body-col' >
                                    {item.get('bankName')}
                                </td>
                                <td className='table-body-col' >
                                    {item.get('money')}
                                </td>
                                <td className='table-body-col' >
                                    {withText(item.get('status'))}
                                </td>
                                <td className='table-body-col red' onClick={ () => props.delLog('del',item.get('id'),index)}>
                                    删除
                                </td>  
                            </tr>
                            
                       )) 
                    }
                </tbody>
            </table>
            <div className="table-foot">
                <div className="table-foot-count">共{props.tbody.size || props.with.size || 0}条记录</div>
                <div className="table-del-box">
                    <img src={rmIcon} alt="del icon" />
                    <span className="table-del-text" onClick={ () => props.delLog('all',0,0)}>情空</span>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        codeUrl: state.getIn(['pay','codeUrl'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleZfbPayMent (data) {
            dispatch(createActions.handleZfbPayMent(data))
        },
        handleWxPayMent (data) {
            dispatch(createActions.handleWxPayMent(data))
        },
        handleBPayMent (data) {
                dispatch(createActions.handleBPayMent(data))
        },
        handleSaveCodeUrl (v) {
            dispatch(createActions.handleSaveCodeUrl(v))
        },
        handleBalancePayGredit (data) {
            dispatch(createActions.handleBalancePayGredit(data))
        },
        handleWXPayGredit (data) {
            dispatch(createActions.handleWXPayGredit(data))
        },
        handleZFBPayGredit (data) {
            dispatch(createActions.handleZFBPayGredit(data))
        },
    } 
}

export default connect(mapStateToProps,mapDispatchToProps)(Table)