import { constants } from './index'
import { fromJS } from 'immutable'
const defaultState = fromJS({
   mode: 0,   //1微信 2支付宝 3余额 
   fast: 0,
   other: '',
   depositLog: 1,
   tableHeadLog: [{name: "充值时间" },{name: "充值方式"},{name: "充值金额", unit:"元"},{name: "充值博拉币",unit:"Q"},{name: "支付状态"},{name: "操作"}],
   tableHeadMember: [{name: "充值时间" },{name: "套餐类型"},{name: "充值方式"},{name: "支付状态"},{name: "操作"}],
   tableWithdrawLog: [{name: "提现时间" },{name: "提现账号"},{name: "银行"},{name: "提现金额",unit:"元"},{name: "提现状态"},{name: "操作"}],
   popupShow: false,
   logList: [],
   memberLogList: [],
   withDrawList: [],
   itemId: '',
   memberFast: -1,
   greditList: [],
   codeUrl: ''
})

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGEPAYMODE: 
       return state.set('mode',action.n)
    case constants.CHANGEFAST: 
      return state.merge({
         fast: action.n,
         other: action.n
      })
    case constants.CHANGEOTHER: 
         return state.merge({
            fast: action.val,
            other: action.val
         })
    case constants.CHANGEDEPOSITLOG:
       return state.set('depositLog',action.n)
    case constants.CHANGEPOPSHOW:
       return state.set('popupShow',action.val)
    case constants.SAVE_PAY_LOG:
       return state.set('logList', fromJS(action.list))
    case constants.SAVE_PAY_MlOG: 
       return state.set('memberLogList', fromJS(action.list))
    case constants.SAVE_ITEM_ID:
       return state.set('itemId',action.id)
    case constants.SAVE_WITHDRAW_LOG: 
       return state.set('withDrawList',fromJS(action.list))
    case constants.SET_MEMBER_FAST: 
       return state.set('memberFast',action.val)
    case constants.SAVE_GREDIT_LIST: 
       return state.set('greditList',action.list)
    case constants.SAVE_CODE_URL: 
       return state.set('codeUrl',action.c)
    default: return state
  }
}

export default reducer