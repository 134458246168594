import React,{ useState,useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/reseale_my.sass'
import Back from '../../../common/back'
import DetailInfo from '../../../common/detailinfo'
import { createActions } from '../store'



const MircoDetail = (props) => {
    const [opernId,setId] = useState(0)
    const { detailInfo,commitList } = props
    useEffect(() => {
        // other code
        let id = props.location.pathname.replace(/[^\d.]/g, "")
        setId(id)
        props.fetchMicroDetail(id)
        props.handleDetailSelected(1)
        props.fetchCommitList('course',id)
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])
    return (
        <div className="opern-detail-box">
            <Back></Back>
            <div className="opern-detail-info">
                <img className="opern-detail-pic" src={detailInfo.get('cover')}  alt="detail img" />
                <div className="opern-detail-content">
                    <div className="d-content-title">
                        <div className="d-content-t">{detailInfo.get('name')}</div>
                    </div>
                    <div className="d-music-video">
                        {
                           detailInfo.toJS().shortVideoList && detailInfo.toJS().shortVideoList.map((item) => (
                                <span key={item.id}> {item.title} </span>
                            ))
                        }
                    </div>
                    <div className="d-content-desc">
                            {detailInfo.get('sectionCount')}课时
                    </div>
                    <div className="d-music-oper d-micro-oper">
                        <div className="d-music-date">{detailInfo.get('createTime')}</div>
                    </div>
                </div>
            </div>

            <DetailInfo 
                selected={props.detailSelect} 
                type={true} 
                click={props.handleDetailSelected} 
                info={detailInfo} 
                commit={commitList} 
            >
            </DetailInfo> 

        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        detailSelect: state.getIn(['reseale','detailSelect']),
        detailInfo: state.getIn(['reseale','detailInfo']),   
        commitList: state.getIn(['reseale','commitList'])   
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleDetailSelected (val) {
            dispatch(createActions.changeDetailSelected(val))
        },
        fetchMicroDetail (id) {
            dispatch(createActions.fetchMicroDetail(id))
        },
        fetchCommitList (name,id) {
            dispatch(createActions.fetchCommitList(name,id))
        } 
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MircoDetail))