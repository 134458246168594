import React,{ PureComponent } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import Copyright from '../../common/copyright'
import Code from '../../common/code'
import { createActions } from './store/'
// import backPic from '../../static/images/fenghua.png'
import backPic from '../../static/images/h.png'
import logoPic from '../../static/images/logo.png'
import '../../static/css/login.sass'
import { Link } from 'react-router-dom'

const background = {
    backgroundImage: `url(${backPic})`,
    backgroundSize: 'cover'
}
let timer = null
class Login extends PureComponent {
   componentDidMount() {
        clearInterval(timer)
        const { getCode,handleIsLogin } = this.props 
        timer = setInterval(() => {
            handleIsLogin()
        },2000)     
        getCode()
   }
   componentWillUnmount() {
        clearInterval(timer)
   }
   render () {
        const { code,token,history } = this.props
        if (token) {
            history.push('/personal')
        }
        return (
            <div className="login-container" style={background}>
                <div className="logo-header-box">
                    <Link to="/"><img src={logoPic}  alt="logo pic" /></Link>    
                
                </div>
                <div className="login-content">
                    <Code isPageLogin={true} code={code}></Code>
                </div>
                <Copyright></Copyright>
            </div> 
        )
   }
}



const mapStateToProps = (state) => {
    return {
      code: state.getIn(['login','code']),
      token: state.getIn(['login','token'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getCode () {
            dispatch(createActions.fetchCode()) 
        },
        handleIsLogin () {
            dispatch(createActions.isLogin()) 
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Login))


