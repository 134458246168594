import React,{ useState,useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import Copyright from '../../common/copyright'
import Code from '../../common/code'


import '../../static/css/home.sass'
import backPic from '../../static/images/fenghua.png'
import andIcon from '../../static/images/and.png'
import andIconA from '../../static/images/and@select.png'
import iosIcon from '../../static/images/ios.png'
import iosIconA from '../../static/images/ios@select.png'
import downIcon from '../../static/images/down.png'
import hIcon from '../../static/images/h.png'
import bIcon from '../../static/images/b.png'

import oIcon from '../../static/images/1.png'
import twoIcon from '../../static/images/2.png'
import tIcon from '../../static/images/3.png'


import s1Icon from '../../static/images/s1.png'
import s2Icon from '../../static/images/s2.png'
import s3Icon from '../../static/images/s3.png'
import mIcon from '../../static/images/m.png'
import fIcon from '../../static/images/f.png'

import { createActions } from '../home/store'

const Home = (props) => {
    const [ background ] = useState({
        backgroundImage: `url(${backPic})`,
        backgroundSize: 'cover'
    })

    const [ bodyBg ] = useState({
        backgroundImage: `url(${bIcon})`,
        backgroundSize: '100% 100%'
    })
    const { 
        isAndroid,
        handleChangeDown,
        fetchUserInfo,
        user,
        token
    } = props
    
    useEffect( () => {
        // other code
            // if (token && !user) {
            //     fetchUserInfo()  
            // }
            // if (user) {
            //     props.fetchUserAuth(user.get('id'))
            // }
       // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[])

    return (
        // <div className="home-container" style={background}>
        //     <div className="home-content">
        //         <Code a={props.androidDown} i={props.iosDown} t={isAndroid}></Code>
        //         <div className="down-box">
        //             <div className={`down-item ${isAndroid === 1 ?  'active': null}`} onClick={ () => handleChangeDown(1)}>
        //                 <img src={isAndroid === 1 ? andIconA : andIcon}  alt="and icon" />
        //                 <div className="down-text">Android </div>
        //                 <img src={downIcon} alt="down icon" />
        //              </div>
        //             <div  className={`down-item ${isAndroid === 2 ?  'active': null}`} onClick={ () => handleChangeDown(2)}>
        //                 <img src={isAndroid === 2 ? iosIconA : iosIcon}  alt="ios icon" />
        //                 <div className="down-text">App Store</div>
        //                 <img src={downIcon}  alt="down icon" />
        //             </div>
        //         </div>
        //     </div>
        //     <Copyright></Copyright>
        // </div>
        <div className='test-box'>
            <img src={hIcon} alt="banner pic" className='banner-pic'/>
            <div className='test-body' style={bodyBg}>
                <div className='j-list'>
                    <img src={oIcon} className="j-pic" />
                    <img src={twoIcon} className="j-pic" />
                    <img src={tIcon} className="j-pic" />
                </div>
                <div className='t-list'>
                    <img src={s1Icon} className="t-pic" />
                    <img src={s2Icon} className="t-pic" />
                    <img src={s3Icon} className="t-pic" />
                </div>
                
                <div className="down-box">
                     <a 
                        className={`down-item ${isAndroid === 1 ?  'active': null}`}
                        download="博拉"
                        href="https://www.pgyer.com/5bb97146ed4403c27e770f4038123e66" 
                     >
                         <img src={andIcon}  alt="and icon" />
                        <div className="down-text">Android </div>
                        <img src={downIcon} alt="down icon" />
                      </a>
                     <a  
                        className={`down-item ${isAndroid === 2 ?  'active': null}`}
                        download="博拉" 
                        href="https://www.pgyer.com/22dff388665dcaa8e08000195dd8e540"
                     >
                         <img src={iosIcon}  alt="ios icon" />
                         <div className="down-text">App Store</div>
                        <img src={downIcon}  alt="down icon" />
                    </a>
                </div>
               
            </div>
            <div className='member-box'>
                <img src={mIcon}  alt="ios icon" className='m-pic'/>    
            </div>
            <div className='f-box'>
                <img src={fIcon}  alt="ios icon" className='f-pic'/>    
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        isAndroid: state.getIn(['home','isAndroid']),
        token: state.getIn(['login','token']),
        user: state.getIn(['home','user']),
        androidDown: state.getIn(['home','androidDown']),
        iosDown: state.getIn(['home','iosDown']) 
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleChangeDown (n) {
            dispatch(createActions.changeDownType(n))
        },
        fetchUserInfo () {
            dispatch(createActions.fetchUserInfo())
        },
        fetchUserAuth (id) {
            dispatch(createActions.fetchUserAuth(id))
        }  
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Home))