import { combineReducers } from 'redux-immutable'
import { reducer as loginReducer } from '../pages/login/store'
import { reducer as homeReducer } from '../pages/home/store'
import { reducer as aboutReducer } from '../pages/home/store'
import { reducer as payReducer } from '../pages/pay/store'
import { reducer as resealeReducer } from '../pages/reseale/store'
import { reducer as personalReducer } from '../pages/personal/store'


const reducer = combineReducers({
  login: loginReducer,
  home: homeReducer,
  about: aboutReducer,
  pay: payReducer,
  reseale: resealeReducer,
  personal: personalReducer
})
  
export default reducer