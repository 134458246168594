import "../static/css/popup.sass"
import rmIcon from '../static/images/close.png'
import { CSSTransition } from 'react-transition-group'
import '../static/css/animation.css'


const Popup = (props) => {
    const { dialogVisible } = props
    return (
        <div className="popup-container">
            <CSSTransition
                in={dialogVisible}
                timeout={200}
                classNames="login"
            >
                <div className="popup-box">
                    <div className="popup-cover"></div>
                    <div className="popup-content">
                        <div className="popup-title">
                            <div className="popup-title-text">删除</div>
                            <img src={rmIcon} alt="del icon" className="del-icon" onClick={props.close}/>
                        </div>
                        <div className="popup-main-text">
                            确认删除充值记录吗？
                        </div>
                        <div className="popup-foot-box">
                            <div className="popup-btn-item">
                                <div className="popup-btn pop-btn__primary" onClick={props.close}>取消</div>
                            </div>
                            <div className="popup-btn-item">
                                 <div className="popup-btn pop-btn__success" onClick={props.confrim}>删除</div>
                            </div>
                        </div>
                    </div>

                </div>
            </CSSTransition>
        </div>
    )
}








export default Popup

