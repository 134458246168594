import { constants } from './index'



export const changeDetailSelected = val => ({
    type: constants.CHANGE_DETAIL_SELECTED,
    val
})

export const fetchMyOpern = (page,pageSize,t) => ({
    type: constants.FETCH_MY_OPERN,
    page,
    pageSize,
    t
})

export const fetchMyMicro = (page,pageSize,t) => ({
    type: constants.FETCH_MY_MICRO,
    page,
    pageSize,
    t
})


export const fetchOpernDetail = id  => ({
    type: constants.FETCH_OPERN_DETAIL,
    id
})

export const fetchMicroDetail = id => ({
    type: constants.FETCH_MICRO_DETAIL,
    id
})

export const handleAddOpern = data => ({
    type: constants.ADD_OPERN_FORM,
    data
})

export const handleAddMicro = data => ({
    type: constants.ADD_MICRO_FORM,
    data
})

export const fetchOpernType = val => ({
    type: constants.FETCH_OPERN_TYPE,
    val
})

export const fetchMyVideo = (page,pageSize,t) => ({
    type: constants.FETCH_MY_VIDEO,
    page,
    pageSize,
    t
})

export const handlePlusOpern = data => ({
    type: constants.ADD_MY_OPERN,
    data
})

export const handlePlusMicro = data => ({
    type: constants.ADD_MY_MICRO,
    data
})

export const handleDelOpernForm = data => ({
    type: constants.DEL_OPERN_FORM,
    data
})

export const handleDelMicrlForm = data => ({
    type: constants.DEL_MICRO_FORM,
    data
})

export const handleDelOpernItem = (id,i) => ({
    type: constants.DEL_OPERN_ITEM,
    id,
    i
})

export const fetchCommitList = (name,id) => ({
    type: constants.FETCH_COMMIT_LIST,
    name,
    id
})


export const handleChangeSelected = (n) => ({
    type: constants.CHANGE_SELECTRD,
    n
})

export const handleChangeSelectedA = (n) => ({
    type: constants.CHANGE_SELECTRD_A,
    n
})