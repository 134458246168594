class Tool {
  timestampToTime(timestamp) {
   var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
   var strDate = ''
   var Y = date.getFullYear() + '/';
   var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
   var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
   var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
   var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
   var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
   strDate = Y+M+D+h+m+s;
   return strDate;  
  }
  
 dateToTime(time) {
   var date = new Date(time)
   var hour = date.getHours();
   var minute = date.getMinutes();
   var second = date.getSeconds();
   return `${hour}:${minute}:${second}`
  }
  
 exportFill (data) {
   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
     let blob = new Blob([data])
     window.navigator.msSaveOrOpenBlob(blob, '数据导出.xls')
   } else {
   /* 火狐谷歌的文件下载方式 */
     var blob = new Blob([ data])
     var downloadElement = document.createElement('a')
     var href = window.URL.createObjectURL(blob)
     downloadElement.href = href
     downloadElement.download = '数据导出.xls'
     document.body.appendChild(downloadElement)
     downloadElement.click()
     document.body.removeChild(downloadElement)
     window.URL.revokeObjectURL(href)
   }
   return ''
 }
}


const tool = new Tool()

export default tool