import React,{ useState } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/pay_deposit.sass'
import Paymode from '../../../common/paymode.js'
import wxIcon from '../../../static/images/wx.png'
import { createActions } from '../store'
import { message, Modal, Drawer } from 'antd'
import QRCode from 'qrcode.react'


const Deposit = (props) => {
    const [ password, setPassword ] = useState('')
    const [isModalVisible, setIsModalVisible] =  useState(false)
    const [val, setVal] = useState('')
    
    const handleNowPay =  () => {
        const { mode, other } = props
        if (mode !== 1 && mode !== 2 && mode !== 3) {
          return  message.warn('请选择支付方式')
        }
        if (!other) {
            return  message.warn('请输入充值金额')
        }

        switch (mode) {
            case 1: 
                return props.handleWxPayMent({
                            bola: other,
                            type: 'qrcode'
                       })
            case 2: 
                return  props.handleZfbPayMent({
                            bola: other,
                            returnUrl: '/html/#/pay'
                        })
            case 3: 
                return setIsModalVisible(true)       
            default: 
                return 
        }
       
        //message.warn('')
    }

    const handleOk = () => {
        const { mode, other } = props
        if (!val) return message.warn('请输入支付密码')
        props.handleBPayMent({
            bola: other, 
            password: val
        })
        setVal('')
        setIsModalVisible(false)  
    }
    const handleCancel = () => {
        setIsModalVisible(false)       
    }
    const handleInpChange = (e) => {
        setVal(e.target.value)
    }
    const onClose = () => {
        props.handleSaveCodeUrl('')
    }
    return (
        <div className="deposit-box">
             <Modal title="支付密码" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消">
                <input type='password' style={{width: '100%',borderRadius: '20px', padding: '10px 20px',border: '1px solid #dedede'}} placeholder="请输入支付密码" value={val} onChange={handleInpChange}/>
            </Modal>
            <Drawer title="微信扫码支付" placement="bottom" onClose={onClose} visible={props.codeUrl}  height={500}>
                  <div className='wx-pay-box'>
                        
                        <QRCode size={250} fgColor='#000000' value={props.codeUrl}></QRCode>
                        <div className='wx-pay-b'>
                            <img className='wx-pay-icon' src={wxIcon}></img>
                            <span className='wx-pay-t'>￥{(+props.other / 10).toFixed(2)}</span>
                        </div>
                  </div>
            </Drawer>
            <div className="deposit-head">
                充值中心
            </div>
            <div className="deposit-content">
                <Paymode b={props.user ? props.user.get('balance') : 0} mode={props.mode} handleChangePayMode={props.handleChangePayMode}></Paymode>
                <div className="bola-box">
                    <div className="bola-title">
                        <span className="bola-title-text">支付金额</span>
                        <span className="bola-title-desc">(10博拉币=1元)</span>
                    </div>
                    <div className="bola-list">
                        <div  className={props.fast === 10 ? 'bola-item active' : 'bola-item'} onClick={() => props.handleChangeFast(10)}>
                            <div className="bola-item-title">10博拉币</div>
                            <div className="bola-money">1.00元</div>
                        </div>
                        <div className={props.fast === 50 ? 'bola-item active' : 'bola-item'} onClick={() => props.handleChangeFast(50)}>
                            <div className="bola-item-title">50博拉币</div>
                            <div className="bola-money">5.00元</div>
                        </div>
                        <div className={props.fast === 300 ? 'bola-item active' : 'bola-item'} onClick={() => props.handleChangeFast(300)}>
                            <div className="bola-item-title">300博拉币</div>
                            <div className="bola-money">30.00元</div>
                        </div>
                    </div> 
                    <input type="text" placeholder="输入自定义金额(博拉币)" className="bola-inp" value={props.other} onChange={props.handleChangeOther}/>
                </div>
                <div className="deposit-btn-box">
                    <div className="deposit-btn" onClick={ handleNowPay }>立即支付</div>
                    <div className="deposit-btn-desc">提示：购买成功后，不可退款</div>
                </div>
            </div>
        </div>
    )

   
}


const mapStateToProps = (state) => {
    return {
       mode: state.getIn(['pay','mode']),
       fast: state.getIn(['pay','fast']),
       other: state.getIn(['pay','other']),
       user: state.getIn(['home','user']),
       codeUrl: state.getIn(['pay','codeUrl'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleChangePayMode (n) {
            dispatch(createActions.changePayMode(n))
        },
        handleChangeFast (n) {
            dispatch(createActions.changeFast(n))
        },
        handleChangeOther (e) {
            let n = e.target.value
            if (n === '' || parseInt(n) === parseInt(n)) {
                if (n) {
                    dispatch(createActions.changeOther(parseInt(n)))
                } else {
                    dispatch(createActions.changeOther(n))
                }
                
            } else {
                message.warn('请输入数字')
            }
            
        },
        handleZfbPayMent (data) {
            dispatch(createActions.handleZfbPayMent(data))
        },
        handleWxPayMent (data) {
            dispatch(createActions.handleWxPayMent(data))
        },
        handleBPayMent (data) {
            dispatch(createActions.handleBPayMent(data))
        },
        handleSaveCodeUrl (v) {
            dispatch(createActions.handleSaveCodeUrl(v))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Deposit))