
import "../static/css/ground.sass"


const Ground = () => {
    return (
        <div className="ground-box"></div>
    )
}

export default  Ground