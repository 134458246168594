import "../static/css/copyright.sass"


const Copyright = () => {

    return (
        <div className="copyright-container">
            <div className="copyright-info">
                <div className="copyright-info-item">©2012-2021 博拉琴房</div>
                <div className="copyright-info-item">京ICP备111222333号</div>
                <div className="copyright-info-item">京ICP证140180号</div>
                <div className="copyright-info-item">京网文[2015]0120-070号</div>
                <div className="copyright-info-item">京公网安备11010802030569号</div>
            </div>
            <div className="copyright-contact">
                <div className="copyright-info-item">深圳XXXXXX科技有限公司</div>
                <div className="copyright-info-item">邮箱：bola@music.com</div>
                <div className="copyright-info-item">QQ：123456789</div>
            </div>
        </div>
    )
}


export default Copyright

