import { constants } from './index'
import { fromJS } from 'immutable'
const defaultState = fromJS({
  isWithDraw: false,
  bola: '',
  name: '',
  phone: '',
  code: '',
  bankName: '',
  bankAccount: '',
  countList: {},
  profit: {},
  orderNum: {} 
})

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.WITHDRAW_IS_SHOW: 
      return state.set('isWithDraw',action.val)
    case constants.CHANGE_INPUT_BOLA:
      return state.set('bola',action.val)
    case constants.CHANGE_INPUT_NAME:
      return state.set('name',action.val)
    case constants.CHANGE_INPUT_PHONE:
      return state.set('phone',action.val)
    case constants.CHANGE_INPUT_CODE:
      return state.set('code',action.val)
    case constants.CHANGE_INPUT_BANKNAME:
      return state.set('bankName',action.val)
    case constants.CHANGE_INPUT_BANKACCOUNT:
      return state.set('bankAccount',action.val)
    case constants.SAVE_COUNT: 
      return state.set('countList',fromJS(action.list))
    case constants.SAVE_PROFIT:
      return state.set('profit',fromJS(action.list))
    case constants.SAVE_ORDER:
        return state.set('orderNum',fromJS(action.list))
    default: return state
  }
}

export default reducer



