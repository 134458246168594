import { all } from 'redux-saga/effects'
import loginSaga from './loginSaga'
import homeSaga from './homeSaga'
import resealeSaga from './resealeSaga'
import paySaga from './paySaga'
import personalSaga from './personalSaga'

export default function* rootSaga () {
  yield all([
    loginSaga(),
    homeSaga(),
    resealeSaga(),
    paySaga(),
    personalSaga(),
  ])
}