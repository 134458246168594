import { constants } from './index'
import { fromJS } from 'immutable'
const defaultState = fromJS({
    isAndroid: 1,
    token: localStorage.getItem('token') || null,
    code: null,
})

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.SAVE_LOGIN_CODE: 
      return state.set('code',action.code)
    case constants.SAVE_LOGIN_TOKEN:
      return state.set('token',localStorage.getItem('token') || null)
    default: return state
  }
}

export default reducer