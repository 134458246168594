import fileIcon from '../static/images/file.png'
import translateIcon from '../static/images/translate.png'
import translateIconA from '../static/images/translate@active.png'
import "../static/css/detail_info.sass"
import {
    YoutubeOutlined 
  } from '@ant-design/icons';
  


const DetailInfo = (props) => {
    const { type,selected,click,info,commit } = props


    const setCourseEl =  () => {
        const i = info.toJS()
        return i.sectionsList.map(item => (
            <a href={item.videoUrl} className="micro-video-item" key={item.id} target="_blank">
                <YoutubeOutlined className="play-icon"></YoutubeOutlined>
                <div className="micro-video-item-text">{item.name}</div>
            </a>
        ))
    }
    return (
        <div className="detail-info-container">
            <div className="detail-info-nav">
                <div className="d-info-nav-left">
                    <div  className={selected === 1 ? 'd-info-nav-item active' : 'd-info-nav-item'} onClick={() => click(1)}>简介</div>
                    {
                       type
                         ?  <div className={selected === 2 ? 'd-info-nav-item active' : 'd-info-nav-item'} onClick={() => click(2)}>课程</div>
                         : null
                    }
                    <div className={selected === 3 ? 'd-info-nav-item active' : 'd-info-nav-item'} onClick={() => click(3)}>评论({commit.size})</div>
                </div>
                <div className="d-info-zan">
                   <img src={info.get('isZan') ? translateIconA : translateIcon} alt="zan icon" className="zan" />
                   <div className="d-info-zan-n">{info.get('zanCount')}</div>
                </div>
            </div>
            {
                selected === 1
                    ?  <div className="detail-main-one">
                            <p className="detail-main-text">
                                            {info.get('briefIntro')}
                            </p> 
                            {
                            !type
                                ? <div className="detail-file-down">
                                    <img src={fileIcon} alt="file icon" /> 
                                    <a className="detail-file-name" href={info.get('fileUrl')} target="_blank">{info.get('name')}.PDF</a>
                                </div>
                                : null 
                            }
                        </div>
                    : null 
            }
            {
               selected === 3
                ?               <div className="comment-box">
                                    {
                                        commit.toJS().map(item => (
                                            <div className="comment-item" key={item.id}>
                                                <img className="comment-pic" src={item.userCover}  alt="detail img" />
                                                <div className="comment-content"> 
                                                    <div className="comment-content-top">
                                                        <div className="comment-name">{item.username}:</div>
                                                        <div className="comment-text">{item.content}</div>
                                                    </div>
                                                  
                                                    {
                                                        item.children.length > 0
                                                            ?  <div className="comment-reply-box">
                                                                    {
                                                                    item.children.map(child => (
                                                                        <div className="comment-reply" key={child.id}>
                                                                            <div className="reply-name">{child.username}:</div>
                                                                            <div className="reply-text">  
                                                                                {child.content}
                                                                            </div>
                                                                        </div>
                                                                    ))  
                                                                    }
                                                                </div>
                                                            : null
                                                    }
                                                    <div className="comment-content-bottom">
                                                        {item.createTime}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                : null 
            }
            {
                selected === 2
                    ?  <div className="micro-video-list">
                           {setCourseEl()}
                        </div>
                    : null 
            }
           
        </div>
    )
}


export default DetailInfo