import "../static/css/code.sass"
import QRCode from 'qrcode.react'


const Code = (props) => {
    return (
        <div className="code-box">
            {
                props.isPageLogin
                    ? <div className="logo-code-title">扫码登录</div>
                    : null
            }
            <div className="code-container">
                {
                    props.code 
                        ? <QRCode size={200} fgColor='#000000' value={props.code}></QRCode>
                        : null
                }
                {
                    props.t === 1
                        ? <QRCode size={250} fgColor='#000000' value={props.a}></QRCode>
                        : null
                }
                {
                    props.t === 2
                        ? <QRCode size={250} fgColor='#000000' value={props.i}></QRCode>
                        : null
                }
            </div>
            {
                props.isPageLogin
                    ? <div className="logo-code-desc">
                          使用博拉琴房App扫码登录
                      </div>
                    : null
            }
            
        </div>
    )
}


export default Code