import React,{ useState } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/reseale_opern.sass'
import MicroForm from '../../../common/microform'

import { createActions } from '../store'



const Micro = (props) => {
    const { microForm,handleAddMicro } = props
    const [isShowOpern, setIsShowOpern] = useState(1)
    const handleChangeWindow = (n) => {
        console.log(22222222222)
        setIsShowOpern(n)
    }
    return (
        <div className="opern-wrap">
            <div className="opern-title">发布微课</div>
            <div className="opern-content">
                <MicroForm musicData={microForm} showNo={isShowOpern} changeWindow={handleChangeWindow} add={handleAddMicro}></MicroForm>
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        microForm: state.getIn(['reseale','microForm']),
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleAddMicro (data) {
            dispatch(createActions.handleAddMicro(data))
        } 
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Micro))