import React,{ useState,useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/reseale_my.sass'
import Back from '../../../common/back'
import DetailInfo from '../../../common/detailinfo'
import { createActions } from '../store'



const OpernDetail = (props) => {
   
    const { detailInfo,opernTypeList,commitList } = props
    useEffect(() => {
        // other code
        let id = props.location.pathname.replace(/[^\d.]/g, "")
        props.fetchOpernDetail(id)
        props.fetchOpernType()
        props.handleDetailSelected(1)
        props.fetchCommitList('music_score',id)
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])
    const handleMoodText = (t) => {
        if (t === 1) {
            return '开心' 
        }
        if (t === 2) {
            return '悲伤' 
        }
        if (t === 3) {
            return '愤怒' 
        }
        if (t === 4) {
            return '轻松' 
        }
    }
    const handleDiffText = (t) => {
        if (t === 0) {
            return '简单' 
        }
        if (t === 1) {
            return '进阶' 
        }
        if (t === 2) {
            return '专业' 
        }   
    }
    const handleCopyrightText = (t) => {
        if (t === 1) {
            return '改编' 
        }
        if (t === 2) {
            return '原创' 
        }
        if (t === 3) {
            return '共享' 
        }   
    }
    return (
        <div className="opern-detail-box">
            <Back></Back>
            <div className="opern-detail-info">
                <img className="opern-detail-pic" src={detailInfo.get('cover')}  alt="detail img" />
                <div className="opern-detail-content">
                    <div className="d-content-title">
                        <div className="d-content-t">{detailInfo.get('name')}</div>
                        <div className="d-tag-list">
                            <div className="d-tag-item">
                               {handleMoodText(detailInfo.get('mood'))}
                            </div>
                            <div className="d-tag-item">
                                {handleDiffText(detailInfo.get('difficulty'))}
                            </div>
                        </div>
                    </div>
                    <div className="d-content-desc">
                        {detailInfo.get('translatedName')}
                    </div>
                    <div className="d-music-list">
                       
                        {opernTypeList.map(item => {
                            if (item.get('id') === detailInfo.get('categoryId')) {
                                return <div className="d-music-item" key={item.get('id')}>{item.get('name')}</div>
                            }
                        })}
                        <div className="d-music-item">
                             {handleCopyrightText(detailInfo.get('copyrightType'))}
                        </div>
                        <div className="d-music-item">
                            钢琴
                        </div>
                    </div>
                    <div className="d-music-info">
                        <div className="d-music-info-p">
                            {detailInfo.get('composer')}丨{detailInfo.get('singer')}丨{detailInfo.get('author')}
                        </div>
                    </div>
                    <div className="d-music-video"> 
                        {
                           detailInfo.toJS().shortVideoList && detailInfo.toJS().shortVideoList.map((item) => (
                                <span key={item.id}> {item.title} </span>
                            ))
                        }
                    </div>
                  
                    
                    <div className="d-music-oper">
                        <div className="d-music-date">{detailInfo.get('createTime')}</div>
                        {/* <div className="d-music-del">
                            <img src={delIcon} className="del-icon" alt="del icon"/>
                            <div className="d-music-t">删除</div>
                        </div> */}
                    </div>
                </div>
            </div>

            <DetailInfo 
                selected={props.detailSelect} 
                type={false} 
                click={props.handleDetailSelected}
                info={detailInfo}
                commit={commitList} 
            >
                
            </DetailInfo>

        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        detailSelect: state.getIn(['reseale','detailSelect']),
        detailInfo: state.getIn(['reseale','detailInfo']),
        opernTypeList: state.getIn(['reseale','opernTypeList']),
        commitList: state.getIn(['reseale','commitList'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleDetailSelected (val) {
            dispatch(createActions.changeDetailSelected(val))
        },
        fetchOpernDetail (id) {
            dispatch(createActions.fetchOpernDetail(id))
        },
        fetchOpernType () {
            dispatch(createActions.fetchOpernType('music_score'))
        },
        fetchCommitList (name,id) {
            dispatch(createActions.fetchCommitList(name,id))
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(OpernDetail))