import '../static/css/pdf.sass'


const Pdf = () => {
    return (
        <div className="pdf-box">
           
        </div>
    )
}



export default Pdf