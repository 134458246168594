import React,{ useState } from 'react'
import {connect} from 'react-redux'
import { withRouter,NavLink } from 'react-router-dom'
import '../../../static/css/reseale_my.sass'

import { createActions } from '../store'



const My = (props) => {
    
    const pathname = props.location.pathname
    return (
        <div className="my-wrap">
            <div className="my-content">
                {
                    pathname === '/reseale/me' ||  pathname === '/reseale/me/micro'
                        ?   <div className="my-list">
                                <NavLink className="my-list-item" to="/reseale/me"  exact activeClassName="nav_active">
                                    曲谱
                                </NavLink>
                                <NavLink className="my-list-item" to="/reseale/me/micro"   activeClassName="nav_active">
                                    微课
                                </NavLink>
                            </div>
                        : null
                }
                
                {props.children}
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        opernList: state.getIn(['reseale','opernList']),
        pageSize: state.getIn(['reseale','pageSize']),
        page: state.getIn(['reseale','page'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
     
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(My))