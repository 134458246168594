
import { constants } from './index'

export const changeIsShow = (val) => ({
    type: constants.WITHDRAW_IS_SHOW,
    val
})



export const changeInputBola = val => ({
    type: constants.CHANGE_INPUT_BOLA,
    val
})

export const changeInputName = val => ({
    type: constants.CHANGE_INPUT_NAME,
    val
})

export const changeInputPhone = val => ({
    type: constants.CHANGE_INPUT_PHONE,
    val
})

export const changeInputCode = val => ({
    type: constants.CHANGE_INPUT_CODE,
    val
})

export const changeInputBankName = val => ({
    type: constants.CHANGE_INPUT_BANKNAME,
    val
})

export const changeInputBankAccount = val => ({
    type: constants.CHANGE_INPUT_BANKACCOUNT,
    val
})

export const fetchSmsMessage = (phone,mode) => ({
    type:constants.FETCH_SMS_MESSAGE,
    phone,
    mode
})

export const handleWithDrawMoney = (data) => ({
   type:  constants.WITH_DRAW_MONEY,
   data
})

export const fetchCount = () => ({
    type: constants.FETCH_COUNT
})

export const fetchProfit = (params) => ({
    type: constants.FETCH_PROFIT,
    params
})

export const fetchOrder = (params) => ({
    type: constants.FETCH_ORDER,
    params
})