import request from '../utils/request'


 /**
 * 我的曲谱
 * @param {number} status     无：所有 0待审核 1通过 2拒绝
 * @param {number} pageNo     页
 * @param {number} pageSize   数量
 * @returns  
 */

 export function fetchOpern (params) {
    return request({
        url: '/api/appMusicScore/myList',
        params
    })
 }

  /**
 * 我的微课
 * @param {number} status     无：所有 0待审核 1通过 2拒绝
 * @param {number} pageNo     页
 * @param {number} pageSize   数量
 * @returns  
 */

export function fetchMicro (params) {
    return request({
        url: '/api/appCourse/myList',
        params
    })
 }


   /**
 * 曲谱详情
 * @param {number} id    曲谱id
 * @returns  
 */
 export function fetchOpernDetail (params) {
    return request({
        url: '/api/appMusicScore/myDetails',
        params
    })
 }

 /**
  * 微课详情
  * @returns 
  */
 export function fetchMicroDetail (params) {
    return request({
        url: '/api/appCourse/queryById',
        params
    })
 }


/**
 * 类型
 * @param {string} type   无：所有，course：微课,music_score：曲谱
 * @returns  
 */
export function fetchOpernType (params) {
    return request({
        url: '/api/appCategory/list',
        params
    })
}


  /**
 * 我的短视频
 * @param {number} status     无：所有 0待审核 1通过 2拒绝
 * @param {number} pageNo     页
 * @param {number} pageSize   数量
 * @returns  
 */
export function fetchMyVideo (params) {
    return request({
        url: '/api/appShortVideo/myList',
        params
    })
}


  /**
 * 添加曲谱
 * @returns  
 */
 export function handlePlusOpern (data) {
    return request({
        url: '/api/appMusicScore/add',
        method: 'post',
        data,
        json: true
    })
 }


  /**
 * 添加微课
 * @returns  
 */
export function handlePlusMicro (data) {
    return request({
        url: '/api/appCourse/add',
        method: 'post',
        data,
        json: true
    })
}

/**
 * 删除曲谱
 * @returns  
 */
export function handleDelOpernItem (params) {
    return request({
        url: '/api/appMusicScore/delete',
        method: 'DELETE',
        params
    })
}


/**
 * 获取评论
 * @returns  
 */

export function fetchCommit (params) {
    return request({
        url: '/api/appComment/list',
        params
    })
}


