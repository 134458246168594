
 import React,{ useState,useEffect } from 'react'
 import "../static/css/opern_form.sass"
 import "../static/css/uploads.sass"
 import { Form, Input, Button, Select,Radio,Upload,Space  } from 'antd'
 import defaultImage from '../static/images/default@image.png'
 import { UploadOutlined } from '@ant-design/icons';
 import {connect} from 'react-redux'
 import { createActions } from '../pages/reseale/store';
 import { withRouter } from 'react-router-dom'
 import rmIcon from '../static/images/close@white.png'
 import { message } from 'antd';
 import Pdf from '../common/pdf'
 const normFile = (e) => {
    //  console.log('Upload event:', e);
     if (Array.isArray(e)) {
       return e;
     }
      return e && e.fileList;
   };

 
 const MicroForm = (props) => {
     
     const [isCharge,setIsCharge] = useState(false)
     const handleChargeChange = (e) => {
         if (e.target.value === 1) {
             return setIsCharge(true)
         } else {
             return setIsCharge(false)
         } 
     }
     const { musicData,
        showNo,
        changeWindow,
        add,
        opernTypeList,
        myVideoList,
        opernList,
        token 
    } = props

    const [status,setStatus] = useState(0)
    const [num,setNum] = useState(0)
    const [onum, setOnum ] = useState(0)
    const setTag = (n,i) => {
        setStatus(n)
        if (typeof i === 'number') {
            setNum(i)
        }
    }

    const onFinish = (values) => {

        let d = musicData.toJS()
        if(status === 1) {
            if (showNo !== d[d.length -1].id) {
                message.success(`请完善微课${d.length}`)
            } else {
                d[d.length - 1] = Object.assign({id: d[d.length - 1].id},values) 
                d.push({id: Math.random().toString(16).slice(-8)})
                add(d)
            }  
        } else if (status === 2) {
            if (showNo !== d[d.length -1].id) {
                message.success(`请完善微课${d.length}`)
            } else {
               d[d.length - 1] = Object.assign({id: d[d.length - 1].id},values)
               var d1 = d
               for (var i = 0; i < d1.length; i++) { 
                 if (+d1[i].cover[0].response.code === 200) {
                    d[i].cover = d1[i].cover[0].response.result.url
                    d[i].is_charge === 0 && (d[i].bola = 0)
                    d[i].sectionsList = d[i].sectionsList.map( item => {
                        return { name: item.name,videoUrl: item.videoUrl[0].response.result.url}
                    })
                    d[i].id = i
                    props.handlePlusMicro(d[i])
                 }
               } 
            }  
        }  else if (status === 3) {
            console.log(onum)
            d[onum] = Object.assign({id: d[onum].id},values)
            setOnum(num)
            add(d)
            console.log(d)
        } else {
            let v = values 
            if (+values.cover[0].response.code === 200) {
                v.cover = values.cover[0].response.result.url
                v.is_charge === 0 && (v.bola = 0) 
                v.sectionsList = v.sectionsList.map( item => {
                    return { name: item.name,videoUrl: item.videoUrl[0].response.result.url}
                })
                
                props.handlePlusMicro(v)
            } else {
                message.success('文件上传未成功,添加曲谱失败')
            } 
        }
    }

    useEffect(() => {
        // other code
         props.fetchOpernType()
         props.fetchMyVideo()
         props.fetchMyOpern()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

     return (
         <div className="opern-form">
             {
                 musicData.map((item,i) => (
             
             <Form
                 name="opern"
                 onFinish={onFinish}
                 className={ item.get('id') === showNo ? 'show opern-box' : 'opern-box'}
                 key={item.get('id')}
                 initialValues={{is_charge:0}}
             >
                  <Form.Item>
                        <div className="upload-box">
                            <div className="upload-title">微课上传</div>
                            <div className="upload-list">
                                {
                                    musicData.map((item,index,arr) => (
                                        <Button 
                                            className={showNo === item.get('id') ? 'upload-item active' : 'upload-item'}
                                            key={item.get('id')}
                                            htmlType="submit" 
                                            onClick={() => {changeWindow(item.get('id')); setTag(3,index) }  }>
                                        
                                            <span className="upload-t">  微课 {index + 1} </span>
                                            {
                                            index !== 0 && index === arr.size - 1
                                                    ? <img 
                                                        src={rmIcon} 
                                                        className="rm-icon" 
                                                        alt="close icon" 
                                                        onClick={(e) => props.handleDelMicrlForm(e,musicData,item.get('id'),changeWindow)}
                                                       />
                                                    : null
                                            }
                                            
                                        </Button>
                                    ))
                                }
                                <Button className="upload-item-last" htmlType="submit" onClick={() => setTag(1)}>
                                    +新增上传
                                </Button>
                                <Button className="upload-item-last" htmlType="submit" onClick={() => setTag(2)}>
                                    +批量上传
                                </Button>
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item
                         label="微课名称"
                         name="name"
                         rules={[{ required: true, message: '请输入微课名称' }]}
                     >
                         <Input placeholder="微课名称"/>
                     </Form.Item>
                    
                     <Form.Item
                         label="微课类型"
                         name="categoryId"
                         rules={[{ required: true }]}
                     >
                              <Select
                                 placeholder="请选择微课类型"  
                                
                              >
                                   {
                                        opernTypeList.map(item => (
                                            <Select.Option 
                                                value={item.get('id')} 
                                                key={item.get('id')}
                                            >
                                                {item.get('name')}
                                            </Select.Option>
                                        ))
                                    }
                             </Select>
                     </Form.Item>

                     <Form.Item
                         label="关联曲谱"
                         name="musicScoreIds"
                        
                     >
                              <Select
                                 placeholder="请选择关联曲谱"  
                                 mode="multiple"
                                 className="my-select"
                              >
                                 {
                                        opernList.map(item => (
                                            <Select.Option 
                                                value={item.get('id')} 
                                                key={item.get('id')}
                                            >
                                                {item.get('name')}
                                            </Select.Option>
                                        ))
                                 }
                             </Select>
                     </Form.Item>
                     <Form.Item
                         label="关联短视频"
                         name="shortVideoIds"
                     >
                              <Select
                                 placeholder="请选择关联短视频"  
                                 mode="multiple"
                                 className="my-select"
                              >
                                  {
                                        myVideoList.map((item) => (
                                            <Select.Option 
                                                value={item.get('id')} 
                                                key={item.get('id')}
                                             >
                                                {item.get('title')}
                                            </Select.Option>
                                        ))
                                  }
                             </Select>
                     </Form.Item>

                     <Form.Item 
                        label={
                            <div className="font-panent">上传曲谱封面 
                                <span className="font-tip">(支持jpg、png、tif 格式，大小限制为25MB)</span>
                            </div>
                        }   
                    >
                        <Form.Item
                                name="cover"
                                valuePropName="fileList" 
                                getValueFromEvent={normFile} 
                                noStyle
                                rules={[{ required: true }]}      
                        >
                                <Upload.Dragger 
                                    action={`${process.env.REACT_APP_BASE_API}/api/common/upload`} 
                                    listType="picture" 
                                    accept="image/tiff,image/jpeg,image/png" 
                                    maxCount={1}
                                    headers={
                                        {"X-Access-Token": token}
                                    }
                                    name="file"
                                >
                                    <div className="default-box">
                                        <img src={defaultImage} alt="default images"/>
                                    </div>
                                    <div className="upload-text">可以将图片直接拖拽到此处</div>
                                </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
 
                    
                     <Form.List name="sectionsList" rules={[{ required: true }]}    >
                         
                        {(fields, { add, remove }) => (
                             <>
                           <div className="upload-video-box">
                                <div className="upload-video-title">
                                    <div className="upload-video-title-text">
                                        课程列表
                                    </div>
                                    <div className="upload-video-btn" onClick={() => add()}>+添加</div>
                                </div>
                            </div>
                             {fields.map(({ key, name, fieldKey, ...restField },index) => (
                               <Space key={key}>
                                 <div className="video-item-box">
                                     <div className="video-item-left">
                                         <div className="video-item-text">微课第{index + 1}节</div>
                                         <div className="video-item-unit">(支持MP4、avi、mov、m4v格式，大小限制为1000MB)</div>
                                     </div>
                                     <div className="video-item-right" onClick={() => remove(name)}>删除</div>
                                 </div>
                                 <Form.Item
                                   {...restField}
                                   name={[name, 'name']}
                                   fieldKey={[fieldKey, 'first']}
                                   rules={[{ required: true, message: 'Missing first name' }]}
                                 >
                                   <Input placeholder="课程名称" />
                                 </Form.Item>
                                 <Form.Item
                                        {...restField}
                                        name={[name, 'videoUrl']}
                                        fieldKey={[fieldKey, 'last']}
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        rules={[{ required: true }]} 
                                    >
                                        <Upload
                                            action={`${process.env.REACT_APP_BASE_API}/api/common/upload`}
                                            // action='https://www.mocky.io/v2/5cc8019d300000980a055e76'  
                                            accept=".mp4,.avi,.mov,.m4v"
                                            beforeUpload={() => { return true}}
                                            maxCount={1}
                                            headers={
                                                {"X-Access-Token": token}
                                            }
                                            
                                            name="file"
                                        >
                                            <div className="upload-btn">
                                                    <Button className="upload-button"><UploadOutlined></UploadOutlined>点击上传</Button>
                                            </div>
                                            
                                        </Upload>
                                    </Form.Item>
                               </Space>
                             ))}
                            
                         </>
                         )}
                     </Form.List>

                     <Form.Item
                         label="微课总课时"
                         name="sectionCount"
                         rules={[{ required: true, message: '请输入微课总课时' }]}
                     >
                         <Input placeholder="请输入微课总课时"/>
                     </Form.Item>
 
                     <Form.Item
                         label="微课属性"
                         name="is_charge"
                         
                       
                     >
                          <Radio.Group onChange={handleChargeChange}>
                             <Radio value={0}>免费</Radio>
                             <Radio value={1}>付费</Radio>
                          </Radio.Group>
                     </Form.Item>
                     {
                        isCharge
                            ?   <Form.Item
                                    label="设置售价(博拉币)"
                                    name="bola"
                                    rules={[{ required: true, message: '请输入价格' }]}
                                >
                                    <Input placeholder="设置曲谱点博拉币价格，1元等于10博拉币"/>
                                </Form.Item>
                            : null
                     }
                     <Form.Item
                         name="briefIntro"
                         label="微课介绍"
                         rules={[{ required: true, message: '请输入微课介绍' }]}
                     >
                        <Input.TextArea placeholder="简单介绍下吧~" />
                     </Form.Item>
                     <Form.Item>
                         <div className="upload-btn">
                             <Button  htmlType="submit" className="upload-button" onClick={() => setTag(0)}>
                                 发布微课
                             </Button>
                         </div>
                     </Form.Item>
             </Form> ))
            }  
            <Pdf></Pdf>
         </div>
     )
 }
 
 
 
const mapStateToProps = (state) => {
    return {
        opernTypeList: state.getIn(['reseale','opernTypeList']),
        myVideoList: state.getIn(['reseale','myVideoList']),
        opernList: state.getIn(['reseale','opernList']),
        token: state.getIn(['login','token']) 
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchOpernType () {
            dispatch(createActions.fetchOpernType('course'))
        },
        fetchMyVideo () {
            dispatch(createActions.fetchMyVideo(1,9999,1))
        },
        fetchMyOpern () {
            dispatch(createActions.fetchMyOpern(1,9999,1))
        },
        handlePlusMicro (data) {
            dispatch(createActions.handlePlusMicro(data))
        },
        handleDelMicrlForm (e,meta,i,fn) {
            e.stopPropagation()
            let arr = meta.toJS()
            const index = arr.findIndex(item => item.id === i)
            fn(arr[index - 1].id) 
            arr.splice(index,1)
            dispatch(createActions.handleDelMicrlForm(arr))  
        }  
    }
}

 
 
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MicroForm))