import React,{ useState,useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter,NavLink} from 'react-router-dom'
import '../../static/css/pay.sass'
import Ground from '../../common/ground'
import memberIcona from '../../static/images/member.png'
import logIcon from '../../static/images/log.png'
import withdrawIcon from '../../static/images/withdraw.png'
import depositIcon from  '../../static/images/deposit.png'
import memberIcon from '../../static/images/member@tag.png'

import avatarPic from '../../static/images/avatar.png'
import personAuthPic from '../../static/images/person_auth.png'
import organAuthPic from '../../static/images/organ_auth.png'


import g1 from '../../static/images/icon/g1.png'
import g2 from '../../static/images/icon/g2.png'
import g3 from '../../static/images/icon/g3.png'
import g4 from '../../static/images/icon/g4.png'
import g5 from '../../static/images/icon/g5.png'
import g6 from '../../static/images/icon/g6.png'
import g7 from '../../static/images/icon/g7.png'
import g8 from '../../static/images/icon/g8.png'
import g9 from '../../static/images/icon/g9.png'
import g10 from '../../static/images/icon/g10.png'
import g11 from '../../static/images/icon/g11.png'
import g12 from '../../static/images/icon/g12.png'
import g13 from '../../static/images/icon/g13.png'
import g14 from '../../static/images/icon/g14.png'
import g15 from '../../static/images/icon/g15.png'
import g16 from '../../static/images/icon/g16.png'
import g17 from '../../static/images/icon/g17.png'
import g18 from '../../static/images/icon/g18.png'
import g19 from '../../static/images/icon/g19.png'
import g20 from '../../static/images/icon/g20.png'
import g21 from '../../static/images/icon/g21.png'
import g22 from '../../static/images/icon/g22.png'
import g23 from '../../static/images/icon/g23.png'
import g24 from '../../static/images/icon/g24.png'
import g25 from '../../static/images/icon/g25.png'
import g26 from '../../static/images/icon/g26.png'
import g27 from '../../static/images/icon/g27.png'
import g28 from '../../static/images/icon/g28.png'
import g29 from '../../static/images/icon/g29.png'
import g30 from '../../static/images/icon/g30.png'

const Pay = (props) => {
    
    const [exp, setExp] = useState(0)
    const { token, user, info } = props
    
    if (!token) {
        props.history.push('/login')
    }
    useEffect(() => {
        // other code
        setExp( () => {
            return (user.get('expVlaue') /  user.get('nextExp'))*200
        })
        //eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    const setGrade = (t) => {
        if (t) {
            switch(t) {
                case 1:
                    return <img src={g1} className="grade-icon"/>
                case 2:
                    return <img src={g2} className="grade-icon"/>
                case 3:
                    return <img src={g3} className="grade-icon"/>
                case 4:
                    return <img src={g4} className="grade-icon"/>
                case 5:
                    return <img src={g5} className="grade-icon"/>
                case 6:
                    return <img src={g6} className="grade-icon"/>
                case 7:
                    return <img src={g7} className="grade-icon"/>
                case 8:
                    return <img src={g8} className="grade-icon"/>
                case 9:
                    return <img src={g9} className="grade-icon"/>
                case 10:
                    return <img src={g10} className="grade-icon"/>
                case 11:
                    return <img src={g11} className="grade-icon"/>
                case 12:
                    return <img src={g12} className="grade-icon"/>
                case 13:
                    return <img src={g13} className="grade-icon"/>
                case 14:
                    return <img src={g14} className="grade-icon"/>
                case 15:
                    return <img src={g15} className="grade-icon"/>
                case 16:
                    return <img src={g16} className="grade-icon"/>
                case 17:
                    return <img src={g17} className="grade-icon"/>
                case 18:
                    return <img src={g18} className="grade-icon"/>
                case 19:
                    return <img src={g19} className="grade-icon"/>
                case 20:
                    return <img src={g20} className="grade-icon"/>
                case 21:
                    return <img src={g21} className="grade-icon"/>
                case 22:
                    return <img src={g22} className="grade-icon"/>
                case 23:
                    return <img src={g23} className="grade-icon"/>
                case 24:
                    return <img src={g24} className="grade-icon"/>
                case 25:
                    return <img src={g25} className="grade-icon"/>
                case 26:
                    return <img src={g26} className="grade-icon"/>
                case 27:
                    return <img src={g27} className="grade-icon"/>
                case 28:
                    return <img src={g28} className="grade-icon"/>
                case 29:
                    return <img src={g29} className="grade-icon"/>
                default:
                    return <img src={g30} className="grade-icon"/>
            }
        } else {
            return null
        }
    }
    return (
        <div className="pay-box">
            <Ground></Ground>
            <div className="container pay-content">
                <div className="pay-menu">
                    <div className="pay-top">
                        <div className="pay-user">
                            {
                                  user && user.get('cover')
                                  ? <img className="avatar-pic" src={user.get('cover')}  alt="avatar pic"/>
                                  : user && user.get('username')
                                      ? <img className="avatar-pic" src={avatarPic}  alt="avatar pic"/>
                                      : null
                            }
                            <div className="pay-user-info">
                                {
                                    user
                                        ? user.get('username').length <= 8
                                            ? <div className="pay-user-name">{user.get('username')}</div>
                                            : <div className="pay-user-name">{user.get('username').slice(0,8)}...</div>
                                        : null

                                }
                                {
                                    user 
                                        ?   <div className="pay-user-step">
                                                { setGrade(user.get('level')) }
                                            </div>
                                        : null
                                }
                               
                            </div>
                        </div>
                        <div className="pay-exp-box">
                            <div className="pay-exp-text">
                                <span>EXP:</span>
                                {
                                    user 
                                        ?  <span>
                                                {
                                                    user.get('expVlaue')
                                                }
                                                /
                                                {
                                                   user.get('nextExp')
                                                   // ? ((user.get('level')  + 1) * (user.get('level')  + 1)  * 100 + 1000) > 999999
                                                   //     ? 999999
                                                   //     : (user.get('level')  + 1) * (user.get('level')  + 1)  * 100 + ((user.get('level')  + 1)*1100+((user.get('level')))*100)
                                                   // : 0 * 100 + 1100
                                                }
                                            </span>
                                        : null
                                }
                                
                            </div>
                            <div className="pay-exp">
                                {
                                    exp > 220
                                      ? <div className="pay-line" style={{width: `220px`}}>
                                            <div className="pay-dot"></div>
                                        </div>
                                      : <div className="pay-line" style={{width: `${exp}px`}}>
                                            <div className="pay-dot"></div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="pay-tag-box">
                            <div className="pay-tag">
                                {
                                    user && user.get('isMember')
                                        ? <img className="member-icon" src={memberIcon}  alt="member"/>
                                        : null
                                }
                            </div>
                            {
                                    info
                                        ? info.get('institutionAuthStatus') === 1
                                            ?     <div className="pay-tag">
                                                        <img src={organAuthPic}  className="auth-pic" alt="avatar pic" />
                                                        <span className='tag-text'>{info.get('sign')}</span>
                                                  </div>
                                            : info.get('personalAuthStatus') === 1
                                                ?   <div className="pay-tag">
                                                            <img src={personAuthPic}  className="auth-pic" alt="avatar pic" />
                                                            <span className='tag-text'>{info.get('sign')}</span>
                                                    </div>
                                                : null

                                        : null
                            }

                        </div>
                    </div>
                    <div className="pay-menu-list">
                        <NavLink className="pay-menu-item" to="/pay"  exact activeClassName="selected">
                            <img className="menu-icon" src={depositIcon} alt="menu icon"/>
                            <span className="menu-text">充值中心</span>
                            <div className="menu-line"></div>
                        </NavLink>
                        <NavLink className="pay-menu-item" to="/pay/member"  exact activeClassName="selected">
                            <img className="menu-icon" src={memberIcona} alt="menu icon"/>
                            <span className="menu-text">会员中心</span>
                            <div className="menu-line"></div>
                        </NavLink>
                        <NavLink className="pay-menu-item" to="/pay/log"  exact activeClassName="selected">
                            <img className="menu-icon" src={logIcon} alt="menu icon"/>
                            <span className="menu-text">充值记录</span>
                            <div className="menu-line"></div>
                        </NavLink>
                        <NavLink className="pay-menu-item" to="/pay/withdraw"  exact activeClassName="selected">
                            <img className="menu-icon" src={withdrawIcon} alt="menu icon"/>
                            <span className="menu-text">提现记录</span>
                            <div className="menu-line"></div>
                        </NavLink>
                    </div>
                </div>
                {props.children}

            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        token: state.getIn(['login','token']),
        user: state.getIn(['home','user']),
        info: state.getIn(['home','info'])  
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
     
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Pay))


