export const WITHDRAW_IS_SHOW = 'personal/WITHDRAW_IS_SHOW'
export const CHANGE_INPUT_BOLA = 'personal/CHANGE_INPUT_BOLA'
export const CHANGE_INPUT_NAME = 'personal/CHANGE_INPUT_NAME'
export const CHANGE_INPUT_PHONE = 'personal/CHANGE_INPUT_PHONE'
export const CHANGE_INPUT_CODE = 'personal/CHANGE_INPUT_CODE'
export const CHANGE_INPUT_BANKNAME = 'personal/CHANGE_INPUT_BANKNAME'
export const CHANGE_INPUT_BANKACCOUNT = 'personal/CHANGE_INPUT_BANKACCOUNT'
export const FETCH_SMS_MESSAGE = 'personal/FETCH_SMS_MESSAGE'
export const WITH_DRAW_MONEY = 'personal/WITH_DRAW_MONEY'

export const FETCH_COUNT = 'personal/FETCH_COUNT'
export const SAVE_COUNT = 'personal/SAVE_COUNT'

export const FETCH_PROFIT = 'personal/FETCH_PROFIT'
export const SAVE_PROFIT = 'personal/SAVE_PROFIT'

export const FETCH_ORDER = 'personal/FETCH_ORDER'
export const SAVE_ORDER = 'personal/SAVE_ORDER'