import { constants } from './index'

export const fetchCode = () => ({
    type: constants.FETCH_LOGIN_CODE
})


export const isLogin = () => ({
    type: constants.USER_IS_LOGIN,
})
