export const CHANGE_DETAIL_SELECTED = 'reseale/CHANGE_DETAIL_SELECTED'
export const FETCH_MY_MICRO = 'reseale/FETCH_MY_MICRO'
export const FETCH_MY_OPERN = 'reseale/FETCH_MY_OPERN'
export const SAVE_MY_OPERN = 'reseale/SAVE_MY_OPERN'
export const SAVE_TYPE_INFO = 'reseale/SAVE_TYPE_INFO'
export const SAVE_LIST_TOTAL = 'reseale/SAVE_LIST_TOTAL'
export const FETCH_OPERN_DETAIL = 'reseale/FETCH_OPERN_DETAIL'
export const FETCH_MICRO_DETAIL = 'reseale/FETCH_MICRO_DETAIL'
export const SAVE_OPERN_DETAIL = 'reseale/SAVE_OPERN_DETAIL'
export const ADD_OPERN_FORM = 'reseale/ADD_OPERN_FORM'
export const ADD_MICRO_FORM = 'reseale/ADD_MICRO_FORM'
export const DEL_OPERN_FORM = 'reseale/DEL_OPERN_FORM'
export const DEL_MICRO_FORM = 'reseale/DEL_MICRO_FORM'
export const FETCH_OPERN_TYPE = 'reseale/FETCH_OPERN_TYPE'
export const SAVE_OPERN_TYPE = 'reseale/SAVE_OPERN_TYPE'
export const FETCH_MY_VIDEO = 'reseale/FETCH_MY_VIDEO'
export const SAVE_MY_VIDEO = 'reseale/SAVE_MY_VIDEO'
export const ADD_MY_OPERN = 'reseale/ADD_MY_OPERN'
export const ADD_MY_MICRO = 'reseale/ADD_MY_MICRO'
export const DEL_OPERN_ITEM = 'reseale/DEL_OPERN_ITEM'
export const FETCH_COMMIT_LIST = 'reseale/FETCH_COMMIT_LIST'
export const SAVE_COMMIT_LIST = 'reseale/SAVE_COMMIT_LIST'
export const CHANGE_SELECTRD = 'reseale/CHANGE_SELECTRD'
export const CHANGE_SELECTRD_A = 'reseale/CHANGE_SELECTRD_A'
