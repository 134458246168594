import React,{ useState } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../static/css/about.sass'
import Ground from '../../common/ground'



const About = () => {
   
    return (
        <div className="about-box">
            <Ground></Ground>
            <div className="about-inner container">
                <div className="about-title">关于我们</div>
                <div className="about-content">
                    <div>此处是关于我们的内容此处，是关于我们的内容此处是关于我们的内容此处，是关于我们的内容此处是关于我们的内容此处是关于我们的，内容此处是关于。容此处是关于我们
的内容此处是关于我们的，内容此处是关于。我们的内容此处是关于我们，的内容此处是关于我们的，内容此处是关于我们的内容此处是关于我们的内，容此处是关于我们的内容此
处是关于我们的内容。
      我们的内容此处是关于我们，的内容此处是关于我们的，内容此处是关于我们的内容此处是关于我们的内，容此处是关于我们的内容此处是关于我们的内容我们的内容此处是关
我们，的内容此处是关于我们的，内容此处是关于我们的内容此处是关于我们的内，容此处是关于我们的内容此处是关于我们的内容。</div>
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
       
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
     
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(About))


