import {takeLatest,put,select} from 'redux-saga/effects'
import { constants } from '../pages/personal/store'
import * as api from '../api/personal'
import { message } from 'antd'

function* fetchSmsMessage (data) {
    const { phone, mode } = data 
    try {
        yield api.handleSendMessage({
            phone,
            smsmode: mode
        })
        message.success('短信已发送')
    } catch (e) {
        console.log('personalSaga: fetchSmsMessage -> error',e)
    }
}

function* handleWithDrawMoney (data) {
    try {
        yield api.handleWithDrawMoney(data)
        message.success('提现成功')
        yield put({type: constants.WITHDRAW_IS_SHOW,val: false})
    } catch (e) {
        message.success('提现失败')
        console.log('personalSaga: handleWithDrawMoney -> error',e)
    }

}


function* fetchCount () {
    try {
        const { result } = yield api.fetchCount()
        yield put({type: constants.SAVE_COUNT,list: result})
    } catch (e) {
        console.log('personalSaga: fetchCount -> error',e)
    }
}

function* fetchProfit (params) {
    try {
        const { result } = yield api.fetchProfit(params)
        yield put({type: constants.SAVE_PROFIT,list: result})
    } catch (e) {
        console.log('personalSaga: fetchProfit -> error',e)
    }
}

function* fetchOrder (params) {
    try {
        const { result } = yield api.fetchOrder(params)
        yield put({type: constants.SAVE_ORDER,list: result})
    } catch (e) {
        console.log('personalSaga: fetchOrder -> error',e)
    }
}

function* personalSaga () {
    yield takeLatest(constants.FETCH_SMS_MESSAGE,data => fetchSmsMessage(data))
    yield takeLatest(constants.WITH_DRAW_MONEY,data => handleWithDrawMoney(data.data))
    yield takeLatest(constants.FETCH_COUNT,fetchCount)
    yield takeLatest(constants.FETCH_PROFIT,data => fetchProfit(data.params))
    yield takeLatest(constants.FETCH_ORDER,data => fetchOrder(data.params))
}


export default personalSaga