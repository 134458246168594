import React,{ useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../../static/css/pay_deposit.sass'
import { createActions } from '../store'
import Table from '../../../common/table'
import Popup from '../../../common/popup'

let b = [{
    time: "2021-05-10  10：14",
    No: "621548144461654813",
    mode: "中国工商银行",
    price: "1000",
    status: 2,
    opear: '删除'
},{
    time: "2021-05-10  10：14",
    No: "621548144461655455",
    mode: "中国农业银行",
    price: "1000",
    status: 3,
    opear: '删除'
}]


const Withdraw = (props) => {
    const { tableWithdrawLog,
            handlePopShow,
            handleClosePopShow,
            handlePopShowOK,
            withDrawList,
            popupShow } = props
    useEffect(() => {
        // other code
        props.fetchWithDrawLog()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    return (
        <div className="deposit-box">
            <div className="deposit-head">
                提现记录
            </div>
            <div className="deposit-content">
                    <Table 
                        thead={tableWithdrawLog} 
                        tbody={[]}
                        with={withDrawList}
                        delLog={handlePopShow}
                    >
                    </Table>
                    <Popup dialogVisible={popupShow} close={handleClosePopShow} confrim={handlePopShowOK} confrim={() => handlePopShowOK(props.itemId)}></Popup>     
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        tableWithdrawLog: state.getIn(['pay','tableWithdrawLog']),
        popupShow: state.getIn(['pay','popupShow']),
        withDrawList: state.getIn(['pay','withDrawList']),
        itemId: state.getIn(['pay','itemId'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
          handlePopShow (mode,id,i) {
            dispatch(createActions.changePopShow(true))
            dispatch(createActions.handleSaveItemId(`${mode}_${id}_${i}`))
          },
          handleClosePopShow () {
             dispatch(createActions.changePopShow(false))
          },
          handlePopShowOK (data) {
            let [d,id,i] = data.split('_')
            if (d === 'del') {
                dispatch(createActions.handleDelWithDrawLog(id,i))
            } else {
                dispatch(createActions.handleWithDrawDelAll())
            }   
          },
          fetchWithDrawLog () {
              dispatch(createActions.fetchWithDrawLog(1,50))
          },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Withdraw))