
export const CHANGEPAYMODE = 'pay/CHANGEPAYMODE'
export const CHANGEFAST = 'pay/CHANGEFAST'
export const CHANGEOTHER = 'pay/CHANGEOTHER'
export const CHANGEDEPOSITLOG = 'pay/CHANGEDEPOSITLOG'
export const CHANGEPOPSHOW = 'pay/CHANGEPOPSHOW'


export const FETCH_PAY_LOG = 'pay/FETCH_PAY_LOG'
export const SAVE_PAY_LOG = 'pay/SAVE_PAY_LOG'
export const FETCH_PAY_MlOG = 'pay/FETCH_PAY_MlOG'
export const SAVE_PAY_MlOG = 'pay/SAVE_PAY_MlOG'
export const SAVE_ITEM_ID = 'pay/SAVE_ITEM_ID'

export const DEL_PAY_LOG = 'pay/DEL_PAY_LOG'
export const DEL_PAY_MLOG = 'pay/DEL_PAY_MLOG'

export const DEL_ALL_PAY = 'pay/DEL_ALL_PAY'
export const DEL_ALL_MEMBER = 'pay/DEL_ALL_MEMBER'

export const FETCH_WITHDRAW_LOG = 'pay/FETCH_WITHDRAW_LOG'
export const SAVE_WITHDRAW_LOG = 'pay/SAVE_WITHDRAW_LOG'
export const DEL_WITHDRAW_LOG = 'pay/DEL_WITHDRAW_LOG'
export const DEL_ALL_WITHDRAW = 'pay/DEL_ALL_WITHDRAW'



export const ZFB_PAYMENT = 'pay/ZFB_PAYMENT'
export const SET_MEMBER_FAST = 'pay/SET_MEMBER_FAST'
export const WX_PAYMENT = 'pay/WX_PAYMENT'
export const B_PAYMENT = 'pay/B_PAYMENT'



export const FETCH_GREDIT_LIST = 'pay/FETCH_GREDIT_LIST'
export const SAVE_GREDIT_LIST = 'pay/SAVE_GREDIT_LIST'

export const BALANCE_PAY_GREDIT = 'pay/BALANCE_PAY_GREDIT'
export const WX_PAY_GREDIT = 'pay/WX_PAY_GREDIT'
export const ZFB_PAY_GREDIT = 'pay/ZFB_PAY_GREDIT'

export const SAVE_CODE_URL = 'pay/SAVE_CODE_URL'


