import { LeftOutlined } from '@ant-design/icons';
import "../static/css/back.sass"
import { withRouter } from 'react-router-dom'
const Back = (props) => {
    const handleBack = () => {
        props.history.goBack()
    }
    return (
        <div className="back-box">
            <div className="back-inner" onClick={handleBack}>
                <LeftOutlined className="backIcon"></LeftOutlined>
                <div className="back-inner-text">返回上一页</div>
            </div>
        </div>
    )
}



export default withRouter(Back)