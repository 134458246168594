
import React,{ useState,useEffect } from 'react'
import "../static/css/opern_form.sass"
import { Form, Input, Button, Select,Radio,Upload  } from 'antd'
import defaultImage from '../static/images/default@image.png'
import { UploadOutlined,DownOutlined } from '@ant-design/icons';
import "../static/css/uploads.sass"
import rmIcon from '../static/images/close@white.png'
import PopupChoose from './popupchoose';
import { message } from 'antd';
import {connect} from 'react-redux'
import { createActions } from '../pages/reseale/store';
import { withRouter } from 'react-router-dom'

import { PDFDocument } from 'pdf-lib'
import logo from '../static/images/logo_pdf_c.png'



const normFile = (e) => {
    console.log('Upload event:', e)
    return e.fileList
};



const OpenrForm = (props) => {
    const { musicData,
            showNo,
            changeWindow,
            add,
            opernTypeList,
            myVideoList,
            opernList,
            token 
    } = props
    
    const [isCharge,setIsCharge] = useState(0)
    // const [m,setM] = useState()
    const handleChargeChange = (e,i,a) => {
        let a1 = a.toJS()
       let arr = [...a1]
       arr[i].is_charge = e.target.value
       add(arr)
    }


    const handleWatermark = (e) => {
       
        return new Promise ((reslove,reject) => {
             var file = e 
             var reader = new FileReader()
             reader.readAsDataURL(file)
             reader.onload = async (e) => {
                var imgSrc = e.target.result
                var pdfDoc = await PDFDocument.load(imgSrc)
                var pages = pdfDoc.getPages()
                var imgCover = await fetch(logo).then(res => res.arrayBuffer())
                var eleImgCover = await pdfDoc.embedPng(imgCover)
                pages.forEach(item => {
                    item.drawImage(eleImgCover, {
                        x: item.getWidth() - 120,
                        y: item.getHeight() - 40,
                        width: eleImgCover.width / 8,
                        height: eleImgCover.height / 8,
                    })
                }) 
                var pdfBytes = await pdfDoc.save()
                var blob = new Blob([pdfBytes], {type: 'pdf'})
                reslove(blob)
             }
        }) 
       
    }


    
    const [status,setStatus] = useState(0)
    const [num,setNum] = useState(0)
    const [onum, setOnum ] = useState(0)
    const [id, setId ] = useState(null)
    const setTag = (n,i,id) => {
        setStatus(n)
        if (typeof i === 'number') {
            setNum(i)
        }
        if (id) {
            setId(id)
        }
    }

    
   
    
    const onFinish = (values) => {
       
        //1新增上传 2批量上传
        let d = musicData.toJS()
        //console.log(d)
        if(status === 1) {
            if (showNo !== d[d.length -1].id) {
                message.success(`请完善曲谱${d.length}`)
            } else {
                d[d.length - 1] = Object.assign({id: d[d.length - 1].id},values) 
                d.push({id: Math.random().toString(16).slice(-8)})
                add(d)
               
            }  
        } else if (status === 2) {
            if (showNo !== d[d.length -1].id) {
                message.success(`请完善曲谱${d.length}`)
            } else {
               d[d.length - 1] = Object.assign({id: d[d.length - 1].id},values)
               var d1 = d
               for (var i = 0; i < d1.length; i++) { 
                 if (+d1[i].audioUrl[0].response.code === 200) {
                    d[i].audioUrl = d1[i].audioUrl[0].response.result.url
                    d[i].cover = d1[i].cover[0].response.result.url
                    d[i].fileUrl = d1[i].fileUrl[0].response.result.url
                    d[i].sourceFile && (d[i].sourceFile = d1[i].sourceFile[0].response.result.url)
                    d[i].is_charge === 0 && (d[i].bola = 0)
                    d[i].id = i
                    props.handlePlusOpern(d[i])
                 }
               }
               
            }  
        } else if (status === 3) {
            changeWindow(id)
            d[onum] = Object.assign({id: d[onum].id},values)
            setOnum(num)
            add(d)
            console.log(num)
            console.log(d.length)
            // if (!d[d.length - 1].is_charge && num === d.length - 1) {
            //     setTimeout ( () => {
            //        setIsCharge(0)
            //     },2000)  
            // } 
            console.log(d)
        } else {
            let v = values 
            if (+values.audioUrl[0].response.code === 200) {
                v.audioUrl = values.audioUrl[0].response.result.url
                v.cover = values.cover[0].response.result.url
                v.fileUrl = values.fileUrl[0].response.result.url
                v.sourceFile && (v.sourceFile = values.sourceFile[0].response.result.url)
                v.is_charge === 0 && (v.bola = 0) 
                props.handlePlusOpern(v)
            } else {
                message.success('文件上传未成功,添加曲谱失败')
            }
        }

    }
    

   

    useEffect(() => {
        // other code
        props.fetchOpernType()
        props.fetchMyVideo()
        props.fetchMyMicro()
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])





    return (
        <div className="opern-form">
          
            {
                musicData.map((item,i,arr) => (
                        <Form
                            name="opern"
                            onFinish={onFinish}
                            key={item.get('id')}
                            className={ item.get('id') === showNo ? 'show opern-box' : 'opern-box'}
                            initialValues={{is_charge:0,bola: 0}}
                        >
                            <Form.Item>
                                <div className="upload-box">
                                    <div className="upload-title">曲谱上传</div>
                                    <div className="upload-list">
                                        {
                                            musicData.map((item,index,arr) => (
                                            <Button 
                                                className={showNo === item.get('id') ? 'upload-item active' : 'upload-item'} 
                                                key={item.get('id')} 
                                                htmlType="submit"
                                                onClick={() => { setTag(3,index,item.get('id')) }  }>
                                                
                                                <span className="upload-t">曲谱{index + 1}</span>
                                                {
                                                    index !== 0 && index === arr.size - 1
                                                        ? <img 
                                                            src={rmIcon} 
                                                            className="rm-icon" 
                                                            alt="close icon" 
                                                            onClick={(e) => props.handleDelOpernForm(e,musicData,item.get('id'),changeWindow)}
                                                        />
                                                        : null
                                                }
                                                
                                            </Button>
                                            ))
                                        }
                                        <Button className="upload-item-last" htmlType="submit" onClick={() => setTag(1)}>
                                            +新增上传
                                        </Button>
                                        <Button className="upload-item-last" htmlType="submit" onClick={() => setTag(2)}>
                                            +批量上传
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item
                                    label="曲名"
                                    name="name"
                                    rules={[{ required: true, message: '请输入曲名' }]}
                                >
                                    <Input placeholder="曲谱名称"/>
                                </Form.Item>
                                <Form.Item
                                    label="曲谱译名"
                                    name="translatedName"
                                >
                                    <Input placeholder="曲谱译名名称"/>
                                </Form.Item>
                                <Form.Item
                                    label="曲谱类型"
                                    name="categoryId"
                                    rules={[{ required: true }]}
                                >
                                        <Select
                                            placeholder="请选择曲谱类型"  
                                        
                                        >
                                            {
                                                opernTypeList.map(item => (
                                                    <Select.Option 
                                                        value={item.get('id')} 
                                                        key={item.get('id')}
                                                    >
                                                        {item.get('name')}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                </Form.Item>
                                {/* <div className="chooses-style">
                                    <div className="chooses-style-title">
                                        多项选择
                                    </div>
                                    <div className="choose-style-inp">
                                        <div className="choose-style-text">多选样式</div>
                                        <DownOutlined className="down-icon"></DownOutlined>
                                    </div>
                                </div> */}
                                <Form.Item
                                    label="关联短视频"
                                    name="shortVideoIds"
                                >
                                        <Select
                                            placeholder="请选择关联短视频"  
                                            mode="multiple"
                                            className="my-select"
                                        >
                                            {
                                                myVideoList.map((item) => (
                                                    <Select.Option 
                                                        value={item.get('id')} 
                                                        key={item.get('id')}
                                                    >   
                                                        {item.get('title')}
                                                    </Select.Option>
                                                ))
                                            }
                                            
                                        </Select>
                                </Form.Item>
                                <Form.Item
                                    label="关联微课"
                                    name="courseIds"
                                >
                                        <Select
                                            placeholder="请选择关联微课"  
                                            mode="multiple"
                                            className="my-select"
                                        >
                                            {
                                                opernList.map(item => (
                                                    <Select.Option 
                                                        value={item.get('id')} 
                                                        key={item.get('id')}
                                                    >
                                                        {item.get('name')}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                </Form.Item>

                                <Form.Item
                                    label="选自"
                                    name="fromName"
                                >
                                    <Input placeholder="请输入"/>
                                </Form.Item>
                                
                                <Form.Item
                                    label="曲谱版权"
                                    name="copyrightType"
                                    rules={[{ required: true }]}                
                                >
                                    <Radio.Group>
                                        <Radio value={1}>改编</Radio>
                                        <Radio value={2}>原创</Radio>
                                        <Radio value={3}>共享</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {/* <Form.Item
                                    label="乐器"
                                    name="musical"
                                    rules={[{ required: true }]}
                                >
                                        <Select
                                            placeholder="请选择乐器"  
                                            defaultValue={{ value: 'male3' }}
                                        >
                                            <Select.Option  value="male3">钢琴</Select.Option>
                                        </Select>
                                </Form.Item> */}
                                <div className="chooses-style">
                                    <div className="chooses-style-title">
                                        乐器
                                    </div>
                                    <div className="choose-style-inp">
                                        <div className="choose-style-text">钢琴</div>
                                        <DownOutlined className="down-icon"></DownOutlined>
                                    </div>
                                </div>
                                <Form.Item 
                                    label={
                                        <div className="font-panent">上传曲谱封面 
                                            <span className="font-tip">(支持jpg、png、tif 格式，大小限制为25MB)</span>
                                        </div>
                                    }   
                                >
                                    <Form.Item
                                            name="cover"
                                            valuePropName="fileList" 
                                            getValueFromEvent={normFile} 
                                            noStyle
                                            rules={[{ required: true }]}      
                                    >
                                            <Upload.Dragger 
                                                action={`${process.env.REACT_APP_BASE_API}/api/common/upload`}
                                                listType="picture" 
                                                accept="image/tiff,image/jpeg,image/png" 
                                                maxCount={1}
                                                headers={
                                                    {"X-Access-Token": token}
                                                }
                                                name="file"
                                            >
                                                <div className="default-box">
                                                    <img src={defaultImage} alt="default images"/>
                                                </div>
                                                <div className="upload-text">可以将图片直接拖拽到此处</div>
                                            </Upload.Dragger>
                                    </Form.Item>
                                </Form.Item>
                                
                                <Form.Item
                                    name="fileUrl"
                                    label={<div className="font-panent">上传曲谱 <span className="font-tip">(仅支持pdf文档)</span></div>} 
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[{ required: true }]}    
                                >

                                    <Upload 
                                        action={`${process.env.REACT_APP_BASE_API}/api/common/upload`}
                                        accept="application/pdf" 
                                        maxCount={1}
                                        headers={
                                            {"X-Access-Token": token}
                                        }
                                        beforeUpload={handleWatermark}
                                        name="file"
                                    >
                                        <div className="upload-btn">
                                                <Button className="upload-button"><UploadOutlined></UploadOutlined>点击上传</Button>
                                        </div>
                                        
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    name="audioUrl"
                                    label={<div className="font-panent">上传曲谱音频 <span className="font-tip">(仅支持mp3文件)</span></div>} 
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[{ required: true }]}    
                                >

                                    <Upload  
                                        action={`${process.env.REACT_APP_BASE_API}/api/common/upload`}
                                        accept="audio/mpeg" 
                                        maxCount={1}
                                        headers={
                                            {"X-Access-Token": token}
                                        }
                                        name="file"
                                    >
                                        <div className="upload-btn">
                                                <Button className="upload-button"><UploadOutlined></UploadOutlined>点击上传</Button>
                                        </div>
                                        
                                    </Upload>
                                </Form.Item>

                                <Form.Item
                                    label="曲谱难度"
                                    name="difficulty"
                                    rules={[{ required: true }]}        
                                >
                                    <Radio.Group>
                                        <Radio value={0}>简单</Radio>
                                        <Radio value={1}>进阶</Radio>
                                        <Radio value={2}>专业</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    label="作曲"
                                    name="composer"
                                    rules={[{ required: true, message: '请输入作曲人' }]}
                                >
                                    <Input placeholder="请输入作曲人"/>
                                </Form.Item>

                                <Form.Item
                                    label="歌手"
                                    name="singer"  
                                    rules={[{ required: true }]} 
                                >
                                    <Input placeholder="请输入歌手"/>
                                </Form.Item>

                                <Form.Item
                                    label="编配"
                                    name="author"
                                    rules={[{ required: true }]}    
                                >
                                    <Input placeholder="请输入编配"/>
                                </Form.Item>

                                <Form.Item
                                    label="曲谱属性"
                                    name="is_charge"
                                
                                >
                                    <Radio.Group onChange={(e) => {handleChargeChange(e,i,arr)}}
                                    >
                                        <Radio value={0}>免费</Radio>
                                        <Radio value={1}>付费</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            
                               
                                {
                                    item.get('is_charge')
                                        ?   <Form.Item
                                                label="设置售价(博拉币)"
                                                name="bola"
                                                rules={[{ required: true, message: '请输入价格' }]}
                                            >
                                                <Input placeholder="设置曲谱点博拉币价格，1元等于10博拉币"/>
                                            </Form.Item>
                                        : null
                                }
                            
                                {
                                     item.get('is_charge')
                                        ?   <Form.Item
                                                    name="sourceFile"
                                                    label={<div className="font-panent">上传曲谱源文件 <span className="font-tip">(支持mscz、mscx、mlx、musicxml、mid、midi、Kar、md、mgu、sgu、cap、capx、ove、scw、bmw、sib 格式)</span></div>} 
                                                    valuePropName="fileList"
                                                    getValueFromEvent={normFile}
                                                    rules={[{ required: true }]}    
                                            >
                        
                                                <Upload 
                                                    action={`${process.env.REACT_APP_BASE_API}/api/common/upload`}
                                                    maxCount={1} 
                                                    accept=".mscz,.mscx,.mlx,.musicxml,.mid,.midi,.Kar,.md,.mgu,.sgu,.cap,.capx,.ove,.scw,.bmw,.sib"
                                                    headers={
                                                        {"X-Access-Token": token}
                                                    }
                                                    name="file"
                                                >
                                                    <div className="upload-btn">
                                                        <Button className="upload-button">
                                                            <UploadOutlined></UploadOutlined>点击上传
                                                        </Button>
                                                    </div>
                                                    
                                                </Upload>
                                            </Form.Item>
                                        : null
                                }

                                <Form.Item
                                    name="mood"
                                    label="心情"
                                    rules={[{ required: true }]}
                                >
                                    <Radio.Group>
                                        <Radio.Button value="1">开心</Radio.Button>
                                        <Radio.Button value="2">悲伤</Radio.Button>
                                        <Radio.Button value="3">甜美</Radio.Button>
                                        <Radio.Button value="4">轻松</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    name="briefIntro"
                                    label="曲谱介绍"
                                    rules={[{ required: true, message: '请输入曲谱介绍' }]}
                                >
                                <Input.TextArea placeholder="简单介绍下吧~" />
                                </Form.Item>
                                <Form.Item>
                                    <div className="upload-btn">
                                        <Button  htmlType="submit" className="upload-button" onClick={() => setTag(0)}>
                                            发布曲谱
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                ))
            }
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        opernTypeList: state.getIn(['reseale','opernTypeList']),
        myVideoList: state.getIn(['reseale','myVideoList']),
        opernList: state.getIn(['reseale','opernList']),
        token: state.getIn(['login','token']) 
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchOpernType () {
            dispatch(createActions.fetchOpernType('music_score'))
        },
        fetchMyVideo () {
            dispatch(createActions.fetchMyVideo(1,9999,1))
        },
        fetchMyMicro () {
            dispatch(createActions.fetchMyMicro(1,9999,1))
        },
        handlePlusOpern (data) {
            dispatch(createActions.handlePlusOpern(data))
        },
        handleDelOpernForm (e,meta,i,fn) {
            e.stopPropagation()
            let arr = meta.toJS()
            const index = arr.findIndex(item => item.id === i)
            fn(arr[index - 1].id) 
            arr.splice(index,1)
            dispatch(createActions.handleDelOpernForm(arr))
          
        } 
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(OpenrForm))