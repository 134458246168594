import axios from 'axios'
import qs from 'qs'
import tools from './tools'

const REACT_APP_AUTH_KEY = process.env.REACT_APP_AUTH_KEY

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT
})

    service.interceptors.request.use(
        config => {
            config.headers[REACT_APP_AUTH_KEY] = localStorage.getItem('token')
            if (!config.json) {
                if (config.method === 'post') {
                  config.data = config.data ? qs.stringify(config.data) : {} 
                } else {
                  config.params = config.params ? config.params : {}
                }
                config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
            } else {
                config.params = config.params ? config.params : {}
                config.data = config.data ? config.data : {}
            }
            return config
        },
        error => {
          return Promise.reject(error)
        }
    )

    service.interceptors.response.use(
        response => {
            let res = response.data
            if(response.config.export) {
              return  tools.exportFill(res)
            }
            if (+res.code === 200) {
              return Promise.resolve(res)
              // 0: Illegal token; 1: Other clients logged in; 
            }
            return Promise.reject(res)
        },
        error => {
            return Promise.reject(error)
        }
    )

    export default service