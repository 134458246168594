import { constants } from './index'
import { fromJS } from 'immutable'
const defaultState = fromJS({
  isAndroid: 1,
  user:   fromJS(JSON.parse(localStorage.getItem('user'))) || null,
  info:  fromJS(JSON.parse(localStorage.getItem('info'))) || null,
  androidDown: 'https://cos.pgyer.com/5b6a49f84c87816f769a8bc88bebc4d3.apk?sign=58d540f282bbd93bf43c58dd7673b8fa&t=1631523467&response-content-disposition=attachment%3Bfilename%3D%E6%B0%B4%E5%B0%8F%E9%AC%BC_1.0.0.apk',
  iosDown: 'https://cos.pgyer.com/5ffff1f5ef0c3a4813419ff1cff8fa3b.ipa?sign=5033ed7ed95a5b8fed8f2dc1cf5e1290&t=1631523620&response-content-disposition=attachment%3Bfilename%3D%E6%B0%B4%E5%B0%8F%E9%AC%BC_1.0.0.ipa',
})

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_DOWN_TYPE: 
      return state.set('isAndroid',action.n)
    case constants.SAVE_USER_INFO: 
      return state.set('user',fromJS(JSON.parse(localStorage.getItem('user'))) || null)
    case constants.SAVE_USER_AUTH: 
      return state.set('info',fromJS(JSON.parse(localStorage.getItem('info'))) || null)
    default: return state
  }
}

export default reducer



