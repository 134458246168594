import { withRouter,NavLink,Link } from 'react-router-dom'
import { connect } from 'react-redux'
import '../static/css/head.sass'
import logoPic from '../static/images/logo.png'
import logoutPic from '../static/images/back.png'
import avatarPic from '../static/images/avatar.png'
import personAuthPic from '../static/images/person_auth.png'
import organAuthPic from '../static/images/organ_auth.png'
import { message }  from 'antd'


const Head = (props) => {
    
    let bg = {}
    if (props.location.pathname !== '/') {
        bg = {
            background: `linear-gradient(to right, #384A5E 0%,rgba(30, 36, 43, 1) 20%,#312B2C 100%)`
        }
    }
    const handleLoginMessage = () =>  {
        message.warning('请登录在使用')
    }

    const headerHtml = () => {
        const { location, user, info } = props
        return location.pathname.indexOf('login') === -1
            ? <div className="header-box" style={bg}>
                    <div className="header-inner">
                        <Link className="logo-box" to="/">
                            <img src={logoPic} alt="logo pic"  />
                        </Link>
                        <div className="user-box">
                            <div className="avatar-box">
                                {
                                    user && user.get('cover')
                                        ? <img src={user.get('cover')}  className="avatar" alt="avatar pic" /> 
                                        :  user && user.get('username')
                                            ? <img src={avatarPic}  className="avatar" alt="avatar pic" />  
                                            : null
                                }
                            </div>
                            <div className="user-info">
                                {
                                    user
                                        ?  user.get('username').length <= 8
                                                ? <div className="user-name">{user.get('username')}</div>
                                                : <div className="user-name">{user.get('username').slice(0,8)}...</div> 
                                        : null
                                }
                                {
                                    info
                                        ? info.get('institutionAuthStatus') === 1
                                            ?     <div className="user-tag">
                                                        <img src={organAuthPic}  className="auth-pic" alt="avatar pic" />
                                                        <span>{info.get('sign')}</span>
                                                  </div>
                                            : info.get('personalAuthStatus') === 1
                                                ?   <div className="user-tag">
                                                            <img src={personAuthPic}  className="auth-pic" alt="avatar pic" />
                                                            <span>{info.get('sign')}</span>
                                                    </div>
                                                : null
                                
                                        : null
                                }
                            </div>
                            <div className="user-back">
                                {
                                    user 
                                      ? <img src={logoutPic} alt="back pic" onClick={props.handleLogOut}/>
                                      : null
                                }
                                {
                                    !user
                                        ? <Link  to="/login" className="login-text">登录</Link>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="nav-list">
                            <NavLink to="/"  exact activeClassName="selected" className="nav-item-box">
                                <div className="nav-item">首页</div>
                            </NavLink>
                            {
                                user 
                                    ?   <NavLink to="/personal"  activeClassName="selected" className="nav-item-box">
                                            <div className="nav-item">个人中心</div>
                                        </NavLink>
                                    :   <div className="nav-item-box" onClick={handleLoginMessage}>
                                            <div className="nav-item">个人中心</div>
                                         </div>
                            }
                            {
                                 user 
                                 ?    <NavLink to="/pay"  activeClassName="selected" className="nav-item-box">
                                        <div className="nav-item">充值</div>
                                      </NavLink>
                                 :    <div className="nav-item-box" onClick={handleLoginMessage}>
                                         <div className="nav-item">充值</div>
                                      </div>
                            }
                            {/* <NavLink to="/pay"  activeClassName="selected" className="nav-item-box">
                                        <div className="nav-item">充值</div>
                            </NavLink> */}
                            {
                                 user 
                                    ?    <NavLink to="/reseale"  activeClassName="selected" className="nav-item-box">
                                                <div className="nav-item">发布</div>
                                         </NavLink>
                                    :    <div className="nav-item-box" onClick={handleLoginMessage}>
                                            <div className="nav-item">发布</div>
                                        </div>
                            }
                            <NavLink to="/about"  activeClassName="selected" className="nav-item-box">
                                <div className="nav-item">关于</div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            : null
    }   
    
    return headerHtml()
    
}



const mapStateToProps = (state) => {
    return {
        user: state.getIn(['home','user']),
        info: state.getIn(['home','info'])   
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogOut () {
            localStorage.removeItem('user')
            localStorage.removeItem('info')
            localStorage.removeItem('token')
            message.success('退出成功')
            dispatch({type: 'home/SAVE_USER_INFO'})
            dispatch({type: 'home/SAVE_USER_AUTH'})
            dispatch({type:'login/SAVE_LOGIN_TOKEN'})
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Head))