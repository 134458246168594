import { constants } from './index'


export const changeDownType = (n) => ({
    type: constants.CHANGE_DOWN_TYPE,
    n
})

export const fetchUserInfo = () => ({
    type: constants.FETCH_USER_INFO
})

export const fetchUserAuth = (t) => ({
    type: constants.FETCH_USER_AUTH,
    t
})