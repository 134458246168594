import React,{ useState,useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter,Link } from 'react-router-dom'
import '../../../static/css/reseale_my.sass'
import { createActions } from '../store'



const MyMicro = (props) => {
    const [page] = useState(1)
    const [pageSize] = useState(10000)
    // const [opernType, setOpernType] = useState(0)
    const { typeInfo,opernList,selectedA } = props
    useEffect(() => {
        // other code
            props.fetchMyMicro(page,pageSize,selectedA)
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[])

    const handleChangeType = (n) => {
            props.handleChangeSelectedA(n)
            props.fetchMyMicro(page,pageSize,n)
              
    }

    return (
        <div className="myopern-box">
            <div className="myopern-nav-list">
                <div className={selectedA === 0 ? 'myopern-nav-item active' : 'myopern-nav-item'} onClick={() => handleChangeType(0)}>
                    待审核*{typeInfo.get('waitNum')}
                </div>
                <div className={selectedA === 1 ? 'myopern-nav-item active' : 'myopern-nav-item'} onClick={() => handleChangeType(1)}>
                    已通过*{typeInfo.get('passNum')}
                </div>
                <div className={selectedA === 2 ? 'myopern-nav-item active' : 'myopern-nav-item'} onClick={() => handleChangeType(2)}> 
                    已拒绝*{typeInfo.get('refuseNum')}
                </div>
            </div>
            <div className="myopern-list">
                {
                        opernList.map(item => (
                            <div className="myopern-list-item" key={item.get('id')}>
                                <Link to={"/reseale/me/micro_detail/" + item.get('id')}>
                                    <img  className="myopern-item-pic" src={item.get('cover')} alt="opern img"/>
                                </Link>
                                <div className="myopern-list-item-content">
                                    <div className="myoprn-item-top">
                                        <div className="myoprn-item-title">
                                            <Link to={"/reseale/me/micro_detail/" + item.get('id')} className="myoprn-item-link">
                                                 {item.get('name')}
                                            </Link>
                                        </div>
                                        <div className="myoprn-item-price">{item.get('bola') || 0}博拉币</div>
                                    </div>
                                    <div className="myoprn-item-bottom">
                                        <div className="myoprn-item-date">{item.get('createTime')}</div>
                                    </div>
                                </div> 
                            </div>
                        ))
                 }
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        typeInfo:  state.getIn(['reseale','typeInfo']),
        opernList: state.getIn(['reseale','opernList']),
        selectedA: state.getIn(['reseale','selectedA'])
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
      fetchMyMicro (page,pageSize,type) {
        dispatch(createActions.fetchMyMicro(page,pageSize,type))
      },
      handleChangeSelectedA (n) {
        dispatch(createActions.handleChangeSelectedA(n))
      }  
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MyMicro))